import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';

import animationData from '../../images/generales/chart.json';

function NoChartDataScreen({ text }) {
  return (
    <Container>
      <Lottie loop={true} animationData={animationData} autoPlay={true} />
      <LoadingText>{text ? text : ''}</LoadingText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  flex: 1;
`;

const LoadingText = styled.span`
  margin-top: 24px;
  text-align: center;
  font-weight: 300;
`;

export default NoChartDataScreen;
