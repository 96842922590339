import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { monedas, monedasEn } from '../../data/monedas';
import { orderBy } from '../../helpers/orderBy';
import LoadingScreen from '../generales/LoadingScreen';
import PageLoadingError from '../generales/PageLoadingError';
import LicitacionesDetailMenuEEditModal from './Licitaciones_detail_menuE_editModal';
import { convertTZ } from '../../helpers/convertTZ';
import { numberFormat } from '../../helpers/numberFormat';

export default function LicitacionesDetailMenuE({
  licitacion,
  secciones,
  categorias,
  valores,
  loading,
  error,
  stage,
  isStageActive,
  periodoEntregaOfertas,
}) {
  const intl = useIntl();

  // console.log({ secciones, categorias, licitacion });

  const [modal, setModal] = useState(false);

  let total = 0;
  const categoriasOrdered = [];
  [
    ...secciones.filter((x) => (stage ? x.stage === stage.id : !x.stage)),
    {
      label: intl.formatMessage({
        id: 'app.licitaciones.detailMenuEa',
        defaultMessage: 'Without Section',
      }),
    },
  ].forEach((x) => {
    if (!x.idSeccion) {
      const categoriasFiltered = categorias.filter((y) => !y.section || y.section === '');
      let subTotal = 0;
      const data = categoriasFiltered.map((y) => {
        const valor = valores.find((z) => z.idCategoria === y.idCategoria);
        subTotal = subTotal + (valor?.value || 0);
        return {
          ...y,
          valor,
        };
      });
      total = total + subTotal;
      categoriasOrdered.push({
        sectionName: x.label,
        data,
        subTotal,
      });
    } else {
      const categoriasFiltered = categorias.filter((y) => y.section === x.idSeccion);
      let subTotal = 0;
      const data = categoriasFiltered.map((y) => {
        const valor = valores.find((z) => z.idCategoria === y.idCategoria);
        subTotal = subTotal + (valor?.value || 0);
        return {
          ...y,
          valor,
        };
      });
      total = total + subTotal;
      categoriasOrdered.push({
        sectionName: x.label,
        data,
        subTotal,
      });
    }
  });

  const currencyDetail = (intl.locale === 'es' ? monedasEn : monedas).find(
    (x) => x.id === licitacion.currency
  );

  const onTime = isStageActive && periodoEntregaOfertas;

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputContainer>
        <DataContainer>
          <span>
            <FormattedMessage id="app.licitaciones.configTitleD" defaultMessage={''} />
            {!licitacion.currency ? '' : `${' '}(${currencyDetail.val})`}
          </span>
          <ConceptsContainer onTime={onTime}>
            <div>
              <div>
                <span>{<FormattedMessage id="app.code" defaultMessage={''} />}</span>
                <span>{<FormattedMessage id="app.name" defaultMessage={''} />}</span>
                <span>{<FormattedMessage id="app.amount" defaultMessage={''} />}</span>
              </div>
            </div>

            {categorias.length === 0 ? (
              <p>
                <FormattedMessage id="app.licitaciones.configConceptsC" defaultMessage={''} />
              </p>
            ) : (
              <>
                {orderBy([...categoriasOrdered], 'sectionName').map((y) => {
                  if (y.data.length > 0) {
                    return (
                      <>
                        <div key={y.sectionName}>
                          <span>{y.sectionName}</span>
                          {orderBy(y.data, 'codigo').map((x) => (
                            <div
                              key={x.idCategoria}
                              style={{ borderBottom: '1px solid whitesmoke' }}
                              onClick={onTime ? () => setModal({ open: true, categoria: x }) : null}
                            >
                              <span>{x.codigo}</span>
                              <span>{x.label}</span>
                              <span>
                                {x.valor?.value
                                  ? numberFormat(x.valor?.value, currencyDetail.cs)
                                  : '--'}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                          <div>
                            <span></span>
                            <p>SubTotal</p>

                            <p>{numberFormat(y.subTotal, currencyDetail.cs)}</p>
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
                <div>
                  <div>
                    <span></span>
                    <p>Total</p>
                    <p>{numberFormat(total, currencyDetail.cs)}</p>
                  </div>
                </div>
              </>
            )}
          </ConceptsContainer>
        </DataContainer>
      </InputContainer>
      {modal.open && (
        <LicitacionesDetailMenuEEditModal
          open={modal.open}
          onClose={() => setModal(false)}
          licitacion={licitacion}
          categoria={modal.categoria}
        />
      )}
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  > span:nth-child(1) {
    font-size: 14px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.cincoColor};
    font-weight: 500;
  }
  > span:nth-child(2) {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }
`;

const ConceptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  > p {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
  }

  > div {
    > span {
      font-size: 14px;
      margin-top: 15px;
      /* font-style: italic; */
      /* color: ${(props) => props.theme.primaryColor}; */
    }
    > div {
      display: grid;
      grid-template-columns: 80px 1fr 80px;
      grid-column-gap: 20px;
      cursor: ${(props) => (props.onTime ? 'pointer' : 'not-allowed')};
      > span {
        font-size: 14px;
        font-weight: 300;
      }
      > p {
        margin-top: 10px;
        font-style: italic;
        font-size: 14px;
        font-weight: 500;
      }

      &:hover {
        background-color: whitesmoke;
      }

      > span:nth-child(3) {
        text-align: right;
        padding-right: 20px;
      }
      > p:nth-child(3) {
        text-align: right;
        padding-right: 20px;
      }
    }
    > div:first-child {
      cursor: default;
      &:hover {
        background-color: transparent;
      }
    }
  }

  > div:nth-child(1) {
    > div {
      border-bottom: 1px solid lightgrey;
      margin-bottom: 20px;
      padding-bottom: 5px;
      cursor: default;

      > span {
        font-size: 13px;
        font-weight: 400;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  > div:last-child {
    > div {
      margin-bottom: 20px;
      padding: 5px 0;
      cursor: default;
      background-color: transparent;

      > p {
        color: black;
        font-size: 14px;
        font-weight: 600;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
`;
