import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// App Check currently works with the following Firebase products:
// Realtime Database
// Cloud Firestore
// Cloud Storage
// Cloud Functions (callable functions)
// Authentication (beta; requires upgrade to Firebase Authentication with Identity Platform)
// por lo que no funciona con auth sin IP.

// solo en localhost ocupar este comando para generar el token, no en production
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log('development');
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
