import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import AddArchivoDetail from './AddArchivoDetail';
import CancelModal from './CancelModal';
import { numberFormat } from '../../helpers/numberFormat';

export default function AddArchivo({
  onClose,
  path,
  storagePath,
  maxArchivos,
  currentFolder,
  acceptedFileType,
  idProject,
  idClient,
  disableLogFileChange,
  section,
}) {
  const location = useLocation();
  const intl = useIntl();

  const [archivos, setArchivos] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [startUpload, setStartUpload] = useState([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelarCarga, setCancelarCarga] = useState();
  const [pausarCarga, setPausarCarga] = useState();
  const [reanudarCarga, setReanudarCarga] = useState();
  const [allProgress, setAllProgress] = useState([]);

  const allowSave = archivos.length > 0 ? true : false;

  const validatorHandler = (file) => {
    const indexOf = archivos.findIndex((x) => x.name === file.name && x.size === file.size);
    if (indexOf > -1) {
      return {
        code: intl.formatMessage({ id: 'app.addfile.mesA', defaultMessage: '' }),
        message: intl.formatMessage({
          id: 'app.addfile.mesB',
          defaultMessage: '',
          values: { name: file.name },
        }),
      };
    }

    return null;
  };

  const acceptedFiles = acceptedFileType
    ? {
        accept: acceptedFileType,
      }
    : {};

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
    ...acceptedFiles,
    validator: validatorHandler,
    onDrop: (acceptedFiles, rejectedFiles) => {
      let n = archivos.length;
      let newFiles = [];
      let newRejectedFiles = [];
      acceptedFiles.forEach((file) => {
        n = n + 1;
        if (maxArchivos && n > maxArchivos) {
          newRejectedFiles.push({
            name: file.name,
            message: intl.formatMessage({ id: 'app.addfile.mesC', defaultMessage: '' }),
          });
        } else if (file.size > 15000000 && location.pathname.includes('registrovisual/')) {
          newRejectedFiles.push({
            name: file.name,
            message: `${intl.formatMessage({
              id: 'app.addfile.mesL',
              defaultMessage: '',
            })} (15 Mb)`,
          });
        } else {
          newFiles.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        }
      });
      rejectedFiles.forEach((file) => {
        newRejectedFiles.push({
          name: file.file.name,
          message: file.errors[0].code,
        });
      });
      setArchivos([...archivos, ...newFiles]);
      setRejected([...rejected, ...newRejectedFiles]);
    },
  });

  useEffect(() => {
    if (startUpload === 'start') {
      const initialState = archivos.map((x, index) => {
        return {
          index,
          fileSize: x.size,
          progress: 0,
        };
      });
      setAllProgress(initialState);
    }
  }, [startUpload]);

  const progressHandler = (indexFile, progress) => {
    //importante esta sintaxis de setState(prevState => fn(prevState))
    setAllProgress((prevProgress) =>
      prevProgress.map((el) => (el.index === indexFile ? { ...el, progress } : el))
    );
  };

  //para medir el avance general
  let ready = true;
  let listos = 0;
  let totalSize = 0;
  let totalSizeUpload = 0;
  allProgress.forEach((x) => {
    totalSize = totalSize + x.fileSize;
    totalSizeUpload = totalSizeUpload + (x.fileSize * x.progress) / 100;
    if (x.progress < 100) {
      ready = false;
    } else {
      listos = listos + 1;
    }
  });

  const cancelAllTask = () => {
    setCancelarCarga('cancelar');
  };
  const pauseAllTask = () => {
    setPausarCarga('pausar');
    setReanudarCarga();
  };
  const resumeAllTask = () => {
    setReanudarCarga('reanudar');
    setPausarCarga();
  };

  const archivosAgregados = () => (
    <Archivos>
      {archivos.length > 0 && (
        <span>
          <FormattedMessage id="app.addfile.mesD" defaultMessage="" />
        </span>
      )}
      <div>
        {archivos.map((file, index) => (
          <AddArchivoDetail
            key={file.name + index}
            file={file}
            index={index}
            archivos={archivos}
            setArchivos={setArchivos}
            currentFolder={currentFolder}
            path={path}
            storagePath={storagePath}
            startUpload={startUpload}
            progressHandler={(i, j) => progressHandler(i, j)}
            cancelarCarga={cancelarCarga}
            pausarCarga={pausarCarga}
            reanudarCarga={reanudarCarga}
            idProject={idProject}
            idClient={idClient}
            disableLogFileChange={disableLogFileChange}
            section={section}
          />
        ))}
      </div>
    </Archivos>
  );

  return (
    <>
      <Container
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        {startUpload !== 'start' && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            {isDragActive ? (
              <div>
                <span>
                  <FormattedMessage id="app.addfile.mesE" defaultMessage="" /> ...
                </span>
              </div>
            ) : (
              <div>
                <span>
                  <FormattedMessage id="app.addfile.mesF" defaultMessage="" />
                </span>
                <em>
                  (
                  {maxArchivos
                    ? `${intl.formatMessage({
                        id: 'app.addfile.mesG',
                        defaultMessage: '',
                      })}: ${maxArchivos}. `
                    : null}
                  <FormattedMessage id="app.addfile.mesH" defaultMessage="" />)
                </em>
              </div>
            )}
          </div>
        )}
        {startUpload === 'start' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ marginBottom: '20px' }}>
              <FormattedMessage id="app.progress" defaultMessage="" />: {listos} de{' '}
              {allProgress.length} <FormattedMessage id="app.files" defaultMessage="" />
            </span>
            <span>
              <FormattedMessage id="app.addfile.mesM" defaultMessage="" />:{' '}
              {totalSize > 0 ? numberFormat(((totalSizeUpload / totalSize) * 10000) / 100, 0) : 0}%
            </span>
          </div>
        )}
        {archivosAgregados()}
      </Container>
      {rejected.length > 0 && (
        <RejectedContainer>
          <div>
            <span>
              <FormattedMessage id="app.addfile.mesI" defaultMessage="" />
            </span>
            <div>
              <span onClick={() => setRejected([])}>
                <FormattedMessage id="app.clean" defaultMessage="" />
              </span>
            </div>
          </div>
          <div>
            {rejected.map((value, index) => (
              <div key={index}>
                <span key={index}>{index + 1}</span>
                <span key={index}>{value.name}</span>
                <span key={index}>{value.message}</span>
              </div>
            ))}
          </div>
        </RejectedContainer>
      )}
      {startUpload !== 'start' && (
        <ButtonsContainer allowSave={allowSave} startUpload={startUpload}>
          <div onClick={allowSave ? () => setStartUpload('start') : null}>
            <FormattedMessage id="app.save" defaultMessage="" />
          </div>
        </ButtonsContainer>
      )}
      {allowSave && startUpload === 'start' && !ready && cancelarCarga !== 'cancelar' && (
        <ButtonsContainer allowSave={allowSave} startUpload={startUpload}>
          <div onClick={() => setOpenCancelModal(true)}>
            <FormattedMessage id="app.cancel" defaultMessage="" />{' '}
            <FormattedMessage id="app.todo" defaultMessage="" />
          </div>
          {pausarCarga !== 'pausar' && (
            <div onClick={pauseAllTask}>
              <FormattedMessage id="app.pause" defaultMessage="" />{' '}
              <FormattedMessage id="app.todo" defaultMessage="" />
            </div>
          )}
          {pausarCarga === 'pausar' && (
            <div onClick={resumeAllTask}>
              <FormattedMessage id="app.resume" defaultMessage="" />{' '}
              <FormattedMessage id="app.todo" defaultMessage="" />
            </div>
          )}
        </ButtonsContainer>
      )}
      {allowSave && startUpload === 'start' && (ready || cancelarCarga === 'cancelar') && (
        <ButtonsContainer allowSave={allowSave} startUpload={false}>
          <div onClick={() => onClose()}>
            <FormattedMessage id="app.close" defaultMessage="" />
          </div>
        </ButtonsContainer>
      )}
      {openCancelModal && (
        <CancelModal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          cancelAllTask={cancelAllTask}
        />
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    background-color: ${(props) =>
      props.theme.name === 'light'
        ? props.isDragActive
          ? 'white'
          : 'whitesmoke'
        : props.isDragActive
        ? 'black'
        : 'rgba(255, 255, 255, 0.1)'};
    border-radius: 4px;
    border-width: 2px;
    border-color: ${(props) =>
      props.theme.name === 'light'
        ? props.isDragAccept
          ? '#80bdff'
          : (props) =>
              props.isDragReject ? '#ff1744' : props.isDragActive ? '#2196f3' : 'lightgrey'
        : props.isDragAccept
        ? '#80bdff'
        : (props) => (props.isDragReject ? '#ff1744' : props.isDragActive ? '#2196f3' : '#eeeeee')};
    border-style: dashed;
    min-height: 150px;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      > span {
        font-weight: 300;
        color: ${(props) => (props.theme.name === 'light' ? 'darkgrey' : '#bdbdbd')};
      }

      > em {
        font-size: 14px;
        margin-top: 10px;
        font-weight: 300;
        color: ${(props) => (props.theme.name === 'light' ? 'darkgrey' : '#bdbdbd')};
      }
    }
  }
`;

const RejectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  > div:nth-child(1) {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    justify-content: space-between;

    > span:first-child {
      font-size: 14px;
    }
    > div {
      display: flex;
      background-color: darkgray;
      border-radius: 4px;
      padding: 1px 10px;
      margin-left: 10px;
      cursor: pointer;

      > span {
        color: white;
        font-size: 12px;
      }

      &:hover {
        background-color: ${(props) => props.theme.orangeColor};
      }
    }
  }

  > div:nth-child(2) {
    > div {
      display: grid;
      grid-template-columns: 40px 1fr 200px;
      grid-column-gap: 20px;
      background-color: whitesmoke;
      margin-bottom: 5px;
      padding: 3px 10px;
      border-radius: 4px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      > span {
        font-size: 13px;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        background-color: darkgrey;

        > span {
          color: white;
        }
      }
    }
  }

  > span {
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
  }
`;

const Archivos = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > span {
    font-size: 14px;
    margin: 15px 0;
  }

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: whitesmoke;
      margin-bottom: 5px;
      padding: 3px 10px;
      border-radius: 4px;
      transition: all ease-in-out 0.2s;

      > div:first-child {
        display: flex;
        align-items: center;

        > span {
          font-size: 14px;
          font-weight: 300;
          cursor: pointer;
        }
      }

      > .MuiSvgIcon-root {
        opacity: 0;
        font-size: 20px;
        color: grey;
      }

      > div:last-child {
        display: flex;
        opacity: 0;
        font-size: 20px;
        background-color: white;
        border-radius: 4px;
        padding: 2px 10px;
        transition: all ease-in-out 0.2s;
        cursor: pointer;

        > span {
          font-size: 10px;
          color: grey;
        }
      }

      &:hover {
        background-color: ${(props) => (props.theme.name === 'light' ? 'lightgrey' : 'black')};
        transition: all ease-in-out 0.2s;

        div {
          opacity: 1;
        }
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  margin: 40px 0 40px 0;

  @media (max-width: 760px) {
    flex-direction: column;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.allowSave && props.startUpload !== 'start'
        ? props.theme.primaryColor
        : props.allowSave && props.startUpload === 'start'
        ? props.theme.primaryColor
        : 'lightgrey'};

    padding: 5px 10px;
    border-radius: 4px;
    cursor: ${(props) => (props.allowSave ? 'pointer' : 'not-allowed')};
    transition: all ease-in-out 0.1s;
    font-size: 14px;
    color: white;
    width: 150px;

    &:hover {
      transform: ${(props) => (props.allowSave ? 'scale(1.05)' : null)};
      transition: all ease-in-out 0.1s;
      background-color: ${(props) => (props.allowSave ? props.theme.orangeColor : 'lightgrey')};
    }
  }

  > div:first-child {
    margin-right: 10px;

    @media (max-width: 760px) {
      margin-right: 0;
    }
  }
  > div:last-child {
    margin-left: 10px;

    @media (max-width: 760px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;
