import { meses } from '../data/time';

export const dateToDate = (date) => {
  if (date) {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const monthString = (meses[date.getMonth()] || '').toUpperCase();
    const year = date.getFullYear();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return {
      date: `${day}-${month}-${year}`,
      dateEn: `${month}-${day}-${year}`,
      hour: `${hours}:${minutes}hrs`,
      dateHour: `${day}-${month}-${year} ${hours}:${minutes}hrs`,
      dateHourEn: `${month}-${day}-${year} ${hours}:${minutes}hrs`,
      dateLong: `${date.getDate()} de ${monthString}, ${year}`,
      dateLongEn: `${monthString} ${date.getDate()}, ${year}`,
    };
  } else {
    return {};
  }
};
