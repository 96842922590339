import React from 'react';
import styled from 'styled-components';

import notFoundImage from '../../images/generales/undraw_page_not_found_re_e9o6.svg';

export default function FallbackComponent() {
  return (
    <Container>
      <ContentContainer>
        <img src={notFoundImage} alt="" />
        <span>{'Oops! Has encontrado un error'}</span>
        <a href={process.env.REACT_APP_SENTRY_RESET_ROUTE}>
          <div>
            <span>REINICIAR</span>
          </div>
        </a>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  height: calc(100vh - 20px);
  flex: 1;
  overflow-y: scroll;
  background-color: white;
  border-radius: 8px;
  margin: 10px;
  box-shadow: ${(props) =>
    props.theme.name === 'light'
      ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
      : '0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07),0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)'};

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    padding: 15px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  max-width: 1024px;

  > img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
  }

  > span {
    margin-top: 24px;
    text-align: center;
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 40px;
  }

  > div:nth-child(3) {
    display: flex;
    padding: 15px;
    background-color: whitesmoke;
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;

    > span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  > div:nth-child(4) {
    display: flex;
    padding: 15px;
    background-color: whitesmoke;
    width: 100%;
    max-width: 400px;
    margin-bottom: 40px;

    > span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  > a {
    text-decoration: none;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #7b83b9;
      padding: 0px 10px;
      margin-top: 24px;
      cursor: pointer;

      > span {
        font-size: 14px;
        font-weight: 300;
        color: white;
      }

      &:hover {
        background-color: #192685;
      }
    }
  }
`;
