import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { rutValidator } from '../../helpers/rutValidator';
import { chapterValidator } from '../../helpers/chapterValidator';
import { selectTheme } from '../../features/theme/themeSlice';

export const FormInputText = ({
  name,
  control,
  label,
  adornment,
  endAdornment,
  type,
  required,
  marginBottom,
  disabled,
  emailPattern,
  maxLength,
  minLength,
  rut,
  urlPattern,
  chapter,
  largeSize,
}) => {
  const theme = useSelector(selectTheme);

  const registerOptions = {
    general: {
      required: { value: required, message: 'Este campo es requerido' },
    },
    email: {
      required: { value: required, message: 'Este campo es requerido' },
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'invalid email address',
      },
    },
    rut: {
      validate: {
        isRut: (x) =>
          required
            ? rutValidator.validaRut(x) || 'Rut inválido'
            : x
            ? rutValidator.validaRut(x) || 'Rut inválido'
            : true,
      },
    },
    url: {
      required: { value: required, message: 'Este campo es requerido' },
      pattern: {
        value:
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        message: 'invalid url',
      },
    },
    chapter: {
      validate: {
        isChapter: (x) =>
          required
            ? chapterValidator(x) || 'Id inválido'
            : x
            ? chapterValidator(x) || 'Id inválido'
            : true,
      },
    },
  };

  const rules = emailPattern
    ? registerOptions.email
    : rut
    ? registerOptions.rut
    : urlPattern
    ? registerOptions.url
    : chapter
    ? registerOptions.chapter
    : registerOptions.general;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <StyledTextField
          selecttheme={theme}
          helperText={error ? error.message : null}
          size={largeSize ? 'medium' : 'small'}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          placeholder={label}
          label={label}
          variant={'outlined'}
          style={{ marginBottom: marginBottom ? marginBottom : '20px' }}
          type={type}
          disabled={disabled}
          inputProps={{
            maxLength: maxLength ? maxLength : 1000,
            minLength: minLength ? minLength : 0,
          }}
          InputProps={{
            startAdornment: adornment ? (
              <StyledInputAdornment position="start" selecttheme={theme}>
                {adornment}
              </StyledInputAdornment>
            ) : null,
            endAdornment: endAdornment ? (
              <StyledInputAdornment position="start" selecttheme={theme}>
                {endAdornment}
              </StyledInputAdornment>
            ) : null,
          }}
        />
      )}
    />
  );
};

const StyledTextField = styled(TextField)(({ selecttheme }) => ({
  '& label': {
    color: selecttheme === 'light' ? 'grey' : 'white',
    fontSize: 14,
    fontWeight: 300,
  },
  '& input': {
    color: selecttheme === 'light' ? 'grey' : 'white',
    fontSize: 14,
    fontWeight: 300,
  },
  '& fieldset': {
    backgroundColor: selecttheme === 'light' ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
  },
  '& label.Mui-focused': {
    color: '#80bdff',
  },
  '& input:valid + fieldset': {
    borderColor: '#ced4da',
    borderWidth: 1,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& input:hover fieldset': {
    borderColor: 'yellow',
  },
  '& input:valid:focus + fieldset': {
    borderColor: '#80bdff',
    padding: '4px !important', // override inline-style
  },
  '&:hover fieldset': {
    borderColor: '#80bdff !important',
  },
}));

const StyledInputAdornment = styled(InputAdornment)(({ selecttheme }) => ({
  '& p': {
    color: selecttheme === 'light' ? 'grey' : 'white',
  },
}));
