import React, { Component } from 'react';
import styled from 'styled-components';

import ViewSDKClient from './ViewSDKClient';

class FullWindow extends Component {
  componentDidMount() {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      /* By default the embed mode will be Full Window */
      viewSDKClient.previewFile(
        this.props.divId ? this.props.divId : 'pdf-div',
        { dockPageControls: false },
        this.props.url,
        this.props.fileName,
        this.props.idFile
      );
    });
  }

  render() {
    return (
      <Container id={this.props.divId ? this.props.divId : 'pdf-div'} className="full-window-div" />
    );
  }
}

const Container = styled.div`
  /* -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  > iframe {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  } */

  @media (max-width: 500px) {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

    > iframe {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
    }
  }
`;

export default FullWindow;
