export const offerDocTypes = [
  {
    id: 'id1',
    label: 'Imagen (jpeg, png)',
    types: { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] },
  },
  { id: 'id2', label: 'PDF (pdf)', types: { 'application/pdf': ['.pdf'] } },
  {
    id: 'id3',
    label: 'Excel (xls, xlsx)',
    types: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  },
  {
    id: 'id4',
    label: 'Word (doc)',
    types: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
  },
  { id: 'id5', label: 'Gantt (mpp, xml, xer)', types: '' },
  { id: 'id6', label: 'Cad (dwg, rvt, ifc, nws)', types: '' },
  { id: 'id7', label: 'Cualquiera', types: '' },
];
