import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/user/userSlice';
import { checkTranslationStatus, getAccessToken, obtainMetadata } from './forge-helpers';
import LoadingScreen from '../generales/LoadingScreen';
import Viewer from './Viewer';
import NoViews from '../generales/NoViews';
import EmptyScreen from '../generales/EmptyScreen';
import { useWindowSize } from 'react-use';

export default function ForgeViewerView({ file, height, viewerId, viewer }) {
  const role = useSelector(selectUser).role;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();
  const [status, setStatus] = useState();
  const [manifest, setManifest] = useState();
  const [metadata, setMetadata] = useState();

  const existUrn =
    file && file.files && file.files[0] && file.files[0].urn
      ? file.files[0].urn
      : file && file.urn
      ? file.urn
      : false;

  const message =
    status === 'pending'
      ? 'Archivo en proceso de codficación'
      : status === 'inprogress'
      ? `El archivo se está codificando, (${progress})`
      : status === 'failed'
      ? 'La codificación del archivo falló'
      : status === 'timeout'
      ? 'No se han generado vistas del archivo'
      : 'No se han generado vistas del archivo';

  const forgeFunctions = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken(role);
      const manifest = await checkTranslationStatus(token.access_token, existUrn);
      const metadataForge = await obtainMetadata(token.access_token, existUrn);
      // console.log({ derivatives: manifest.derivatives[0].children });
      setMetadata(metadataForge);
      setManifest(manifest);
      setProgress(manifest.progress);
      setStatus(manifest.status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (existUrn) {
      forgeFunctions();
    }
  }, []);

  const url = file
    ? file?.metadata.includes('image') && file.uriLarge
      ? file.uriLarge
      : file.uri
    : '';

  const name = (file?.name || '').split('_');
  name.shift();

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: height ? height : '100%',
      }}
    >
      {!existUrn ? (
        <NoViews text={'No se han generado vistas del archivo'} url={url} />
      ) : loading ? (
        <LoadingScreen text={'Analizando archivo ...'} />
      ) : status === 'success' ? (
        <Viewer urn={existUrn} role={role} viewerId={viewerId} viewer={viewer} />
      ) : status === 'pending' ? (
        <EmptyScreen text={'Archivo en proceso de codficación'} />
      ) : status === 'inprogress' ? (
        <EmptyScreen text={`El archivo se está codificando, (${progress})`} />
      ) : status === 'failed' ? (
        <EmptyScreen text={'La codificación del archivo falló'} />
      ) : status === 'timeout' ? (
        <EmptyScreen text={'No se han generado vistas del modelo'} />
      ) : (
        <NoViews text={message} url={url} />
      )}
    </div>
  );
}
