import React, { useState, useEffect } from 'react';
import { convertTZ } from '../../helpers/convertTZ';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    let timeLeft = {};
    if (!targetDate?._seconds) return timeLeft;
    const difference =
      +convertTZ(new Date(targetDate._seconds * 1000), 'America/Santiago') -
      +convertTZ(new Date(), 'America/Santiago');

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const renderCountdown = () => {
    if (!targetDate?._seconds) {
      return <span>--</span>;
    }
    if (Object.keys(timeLeft).length === 0) {
      return <span>Finalizado</span>;
    }

    return (
      <span>
        {`${timeLeft.days}d ${timeLeft.hours}hr ${timeLeft.minutes}m ${timeLeft.seconds}s`}
      </span>
    );
  };

  return <span>{renderCountdown()}</span>;
};

export default Countdown;
