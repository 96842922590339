import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { useWindowSize } from 'react-use';
import { collection, doc, onSnapshot } from 'firebase/firestore';

import { db } from '../../firebase/firebase';
import { useDeviceDetect } from '../../helpers/useDeviceDetect';
import { selectUser, setUserLoginDetails } from '../../features/user/userSlice';
import { selectAppConfig, setAppConfig } from '../../features/appConfig/appConfigSlice';
import FallbackComponent from '../generales/FallbackComponent';
import ReloadAlert from '../generales/RealoadAlert';
import HomeRoutes from './HomeRoutes';
import Header from './Header';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function Home({ locale }) {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const appConfig = useSelector(selectAppConfig);

  const [openReloadModal, setOpenReloadModal] = useState(false);
  const [hash, setHash] = useState(null);

  const { isMobile } = useDeviceDetect();
  const { height } = useWindowSize();

  window.$fvIdentity = {
    id: user.userId, // Required - string - unique user identifier
    name: user.name, // Required - string - user's name
    email: user.email, // Optional - string - user's email
    disableReplaysForUser: false, // Optional - boolean - disables replays for the user
  };

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    }
  }, []);

  useEffect(() => {
    const version = appConfig.find((x) => x.idConfig === 'app_version');
    if (version && process.env.REACT_APP_VERSION !== version.version) {
      setOpenReloadModal(true);
    }
  }, [appConfig]);

  useEffect(() => {
    if (hash && user.userId) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'b7b3qbqu',
        name: user.name, // Full name
        email: user.email, // Email address
        userId: user.userId,
        user_id: user.userId,
        created_at: new Date().getTime(), // Signup date as a Unix timestamp
        user_hash: hash,
        custom_launcher_selector: '#support',
        language_override: locale,
      });
    }
  }, [hash]);

  useEffect(() => {
    (async () => {
      try {
        const functions = getFunctions();
        const intercom_hash = httpsCallable(functions, 'intercom_hash');
        const result = await intercom_hash();
        setHash(result.data);
      } catch (err) {
        console.log({ subscriber: 'httpsCallable: intercomHash', err });
      }
    })();
  }, []);

  useEffect(() => {
    const subscriber = onSnapshot(
      doc(db, `users/${user.userId}`),
      (doc) => {
        //guardamos cambios cuando ocurran
        dispatch(
          setUserLoginDetails({
            userId: user.userId,
            ...doc.data(),
          })
        );
      },
      (err) => {
        console.log({ subscriber: 'users', err });
      }
    );
    return () => subscriber();
  }, []);

  useEffect(() => {
    const subscriber = onSnapshot(
      collection(db, 'app_config'),
      (querySnapshot) => {
        dispatch(
          setAppConfig(
            querySnapshot.docs.map((x) => {
              return {
                idConfig: x.id,
                ...x.data(),
              };
            })
          )
        );
      },
      (err) => {
        console.log({ subscriber: 'app_config', err });
      }
    );
    return () => subscriber();
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={<FallbackComponent />}
      showDialog
      dialogOptions={{
        user: {
          email: user?.email || '',
          name: user?.name || '',
        },
      }}
      beforeCapture={(scope) => {
        scope.setTag('name', user?.name || 'no esta logeado');
        scope.setTag('email', user?.email || 'no esta logeado');
        scope.setTag('role', user?.role || 'no esta logeado');
      }}
    >
      <HomeContainer height={height} isMobile={isMobile}>
        <Header />
        <HomeRoutes />
      </HomeContainer>
      {openReloadModal && (
        <ReloadAlert open={openReloadModal} onClose={() => setOpenReloadModal(false)} />
      )}
    </Sentry.ErrorBoundary>
  );
}

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => (props.height && props.isMobile ? `${props.height}px` : '100vh')};
  background-color: ${(props) => props.theme.bgGray100};
`;
