import React from 'react';
import styled from 'styled-components';
import MuiIcon from './MuiIcon';

export default function PageButtonHeader({
  icon,
  textIcon,
  name,
  onClick,
  blocked,
  oneChild,
  chat,
  backgroundColor,
  rotateIcon,
  top,
  right,
  bottom,
  left,
}) {
  return !chat ? (
    <Container
      onClick={onClick}
      blocked={blocked}
      oneChild={oneChild}
      backgroundColor={backgroundColor}
      padding={!textIcon && !name ? '2px 5px' : '2px 7px 2px 5px'}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
    >
      {icon && (
        <MuiIcon
          name={icon}
          color={'white'}
          fontSize={'20px'}
          margin={!textIcon && !name ? '0' : '0 5px 0 0'}
          rotate={rotateIcon}
        />
      )}
      {textIcon && <TextIcon>{textIcon}</TextIcon>}
      {name && <Name oneChild={oneChild}>{name}</Name>}
    </Container>
  ) : (
    <ChatContainer onClick={onClick}>
      <MuiIcon name={'Chat'} color={'white'} fontSize={'16px'} />
    </ChatContainer>
  );
}

const Container = styled.div`
  position: absolute;
  top: ${(props) => props.top || null};
  right: ${(props) => props.right || null};
  bottom: ${(props) => props.bottom || null};
  left: ${(props) => props.left || null};
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || props.theme.primaryColor};
  padding: ${(props) => props.padding};
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    transform: ${(props) => (props.blocked ? null : 'scale(1.05)')};
    transition: all ease-in-out 0.1s;
  }
`;

const TextIcon = styled.span`
  font-size: 16px;
  color: white;
  margin-right: ${(props) => (props.oneChild ? '0px' : '5px')};
`;

const Name = styled.span`
  font-size: 12px;
  color: white;
  font-weight: 300;
  padding: ${(props) => (props.oneChild ? '1px 0' : 'none')};
`;

const ChatContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffaa33;
  border-radius: 4px;
  transition: all ease-in-out 0.1s;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all ease-in-out 0.1s;
  }
`;
