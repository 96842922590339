import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { es, enUS } from 'date-fns/locale';
import { useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';

export default function FormDateTime({ value, setValue, label, disabled }) {
  const intl = useIntl();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={intl.locale === 'es' ? es : enUS}
    >
      <DateTimePicker
        value={value}
        onChange={(t) => setValue(t)}
        label={label}
        disabled={disabled}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </LocalizationProvider>
  );
}
