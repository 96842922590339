import React from 'react';

import ModalContainer from '../generales/ModalContainer';
import { useWindowSize } from 'react-use';
import ForgeViewerView from './ForgeViewerView';

export default function ForgeViewerModal({ open, onClose, file }) {
  const { height } = useWindowSize();

  const name = (file?.name || '').split('_');
  name.shift();

  let viewerModal;

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={name.join('_')}
      width={'100%'}
      maxWidth={'95%'}
      maxHeight={'95%'}
      downloadUrl={file.uri || file.url}
    >
      <ForgeViewerView
        file={file}
        height={`${height - 100}px`}
        viewerId={'viewerModal'}
        viewer={viewerModal}
      />
    </ModalContainer>
  );
}
