import React, { useState } from 'react';
import { DocumentViewer } from 'react-documents';
import Spinner from 'react-spinkit';

import EmptyScreen from '../generales/EmptyScreen';

export default function DocView({ url, fileName, idFile, splitview, modal, height }) {
  const [loading, setLoading] = useState(true);

  return !(url && fileName && idFile) ? (
    <EmptyScreen text={'No se ha podido mostrar el archivo'} />
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: height ? height : '100%',
      }}
    >
      {loading ? (
        <Spinner
          className="loading text-center"
          name="three-bounce"
          color="darkgrey"
          fadeIn="none"
        />
      ) : null}
      <DocumentViewer
        style={{
          // borderBottomLeftRadius: splitview ? '0px' : modal ? '4px' : '8px',
          // borderBottomRightRadius: modal ? '4px' : '8px',
          height: loading ? '0px' : '100%',
          width: loading ? '0px' : '100%',
        }}
        queryParams="hl=es-419"
        url={url}
        loaded={() => setLoading(false)}
      />
    </div>
  );
}
