import { configureStore } from '@reduxjs/toolkit';

import appConfigReducer from '../features/appConfig/appConfigSlice';
import userReducer from '../features/user/userSlice';
import themeReducer from '../features/theme/themeSlice';
import licitacionesReducer from '../features/licitaciones/licitacionesSlice';

export const store = configureStore({
  reducer: {
    appConfig: appConfigReducer,
    user: userReducer,
    theme: themeReducer,
    licitaciones: licitacionesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
