import { getAccessToken } from './forge-helpers';

const Autodesk = window.Autodesk;
let viewerHelp;
export var properties = {};

const launchViewer = async (urn, role, dispatch, viewerId, viewer) => {
  const getToken = await getAccessToken(role);

  const options = {
    document: urn,
    env: 'AutodeskProduction2',
    api: 'streamingV2', // 'derivativeV2' or 'derivativeV2_EU'
    getAccessToken: function (onTokenReady) {
      const token = getToken.access_token;
      const timeInSeconds = 3600;
      onTokenReady(token, timeInSeconds);
      // console.log(token);
    },
  };

  Autodesk.Viewing.Initializer(options, function () {
    const config3d = {
      // extensions: ['ForgeVistasExtension'],
      extensions: [],
    };
    const htmlDiv = document.getElementById(viewerId || 'forgeViewer');
    viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, config3d);
    viewerHelp = viewer;
    const startedCode = viewer.start();
    if (startedCode > 0) {
      console.error('Failed to create a Viewer: WebGL not supported.');
      return;
    }
    // console.log('Initialization complete, loading a model next...');
    loadDocument(options.document, dispatch, viewer);
  });
};

function resizeViewer() {
  if (viewerHelp) {
    return viewerHelp.resize();
  }
}

function loadDocument(urn, dispatch, viewer) {
  const documentId = `urn:${urn}`;

  Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

  function onDocumentLoadSuccess(viewerDocument) {
    // const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    // viewer.loadDocumentNode(viewerDocument, defaultModel);
    const viewables = viewerDocument.getRoot().search({ type: 'geometry' });
    // console.log(viewables);
    viewer.loadDocumentNode(viewerDocument, viewables[0]);
  }

  function onDocumentLoadFailure(err) {
    console.error({ err: 'Failed fetching Forge manifest', errMessage: err });
  }
}

const Helpers = {
  launchViewer,
  loadDocument,
  resizeViewer,
};

export default Helpers;
