import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

export default function AddArchivo({ archivos, setArchivos, maxArchivos, tipoArchivo }) {
  const intl = useIntl();
  const [rejected, setRejected] = useState([]);

  const validatorHandler = (file) => {
    const indexOf = archivos.findIndex((x) => x.name === file.name && x.size === file.size);
    if (indexOf > -1) {
      return {
        code: intl.formatMessage({ id: 'app.addfile.mesA', defaultMessage: '' }),
        message: intl.formatMessage({
          id: 'app.addfile.mesB',
          defaultMessage: '',
          values: { name: file.name },
        }),
      };
    }

    return null;
  };

  const acceptFiles = tipoArchivo
    ? {
        accept: tipoArchivo,
      }
    : {};

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
    // accept: tipoArchivo,
    ...acceptFiles,
    validator: validatorHandler,
    onDrop: (acceptedFiles, rejectedFiles) => {
      let n = archivos.length;
      let newFiles = [];
      let newRejectedFiles = [];
      acceptedFiles.forEach((file) => {
        n = n + 1;
        if (maxArchivos && n > maxArchivos) {
          newRejectedFiles.push({
            name: file.name,
            message: intl.formatMessage({ id: 'app.addfile.mesC', defaultMessage: '' }),
          });
        } else {
          newFiles.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        }
      });
      rejectedFiles.forEach((file) => {
        newRejectedFiles.push({
          name: file.file.name,
          message: file.errors[0].code,
        });
      });
      setArchivos([...archivos, ...newFiles]);
      setRejected([...rejected, ...newRejectedFiles]);
    },
  });

  const openFile = (file) => {
    if (file.uri) {
      window.open(file.uri);
    } else {
      window.open(file.preview);
    }
  };

  const deleteFile = (file) => {
    let fileCopy = [...archivos];
    let indexOf = fileCopy.findIndex((x) => x.name === file.name && x.size === file.size);
    if (indexOf > -1) {
      fileCopy.splice(indexOf, 1);
      setArchivos(fileCopy);
    }
  };

  const thumbs = archivos.map((file, index) => (
    <Thumbs key={file.name + index} onClick={() => deleteFile(file)}>
      <DeleteButton>
        <CancelIcon />
      </DeleteButton>
      <ThumbInner>
        <img src={file.uriSmall ? file.uriMedium : file.preview} alt="" />
      </ThumbInner>
    </Thumbs>
  ));

  const archivosAgregados = () => (
    <Archivos>
      {archivos.length > 0 && (
        <span>
          <FormattedMessage id="app.addfile.mesD" defaultMessage="" />
        </span>
      )}
      <div>
        {archivos.map((file, index) => (
          <div key={file.name + index}>
            <IndexContainer>
              <span>{index + 1}</span>
            </IndexContainer>
            <div onClick={() => openFile(file)}>
              <Tooltip title={`${file.name} (${Math.round((file.size / 1000000) * 100) / 100} MB)`}>
                <span>
                  {file.name} ({Math.round((file.size / 1000000) * 100) / 100} MB)
                </span>
              </Tooltip>
            </div>
            <div onClick={() => deleteFile(file)}>
              <span>
                <FormattedMessage id="app.delete" defaultMessage="" />
              </span>
            </div>
          </div>
        ))}
      </div>
    </Archivos>
  );

  return (
    <>
      <Container
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />

          {isDragActive ? (
            <div>
              <span>
                <FormattedMessage id="app.addfile.mesE" defaultMessage="" /> ...
              </span>
            </div>
          ) : (
            <div>
              <span>
                <FormattedMessage id="app.addfile.mesF" defaultMessage="" />
              </span>
              <em>
                (
                {maxArchivos
                  ? `${intl.formatMessage({
                      id: 'app.addfile.mesG',
                      defaultMessage: '',
                    })}: ${maxArchivos}. `
                  : null}
                <FormattedMessage id="app.addfile.mesH" defaultMessage="" />)
              </em>
            </div>
          )}
        </div>
        {tipoArchivo?.includes('image') ? (
          <ThumbsContainer>{thumbs}</ThumbsContainer>
        ) : (
          archivosAgregados()
        )}
      </Container>
      {rejected.length > 0 && (
        <RejectedContainer>
          <div>
            <span>
              <FormattedMessage id="app.addfile.mesI" defaultMessage="" />:
            </span>
            <div>
              <span onClick={() => setRejected([])}>
                <FormattedMessage id="app.delete" defaultMessage="" />
              </span>
            </div>
          </div>
          <div>
            {rejected.map((value, index) => (
              <div key={index}>
                <span>{index + 1}</span>
                <Tooltip title={value.name}>
                  <span>{value.name}</span>
                </Tooltip>
                <span>{value.message}</span>
              </div>
            ))}
          </div>
        </RejectedContainer>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  /* max-width: 1024px; */
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    background-color: ${(props) =>
      props.theme.name === 'light'
        ? props.isDragActive
          ? 'white'
          : 'whitesmoke'
        : props.isDragActive
        ? 'black'
        : 'rgba(255, 255, 255, 0.1)'};
    border-radius: 4px;
    border-width: 2px;
    border-color: ${(props) =>
      props.theme.name === 'light'
        ? props.isDragAccept
          ? '#80bdff'
          : (props) =>
              props.isDragReject ? '#ff1744' : props.isDragActive ? '#2196f3' : 'lightgrey'
        : props.isDragAccept
        ? '#80bdff'
        : (props) => (props.isDragReject ? '#ff1744' : props.isDragActive ? '#2196f3' : '#eeeeee')};
    border-style: dashed;
    min-height: 150px;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      > span {
        font-weight: 300;
        color: ${(props) => (props.theme.name === 'light' ? 'darkgrey' : '#bdbdbd')};
      }
      > em {
        font-size: 14px;
        margin-top: 10px;
        font-weight: 300;
        color: ${(props) => (props.theme.name === 'light' ? 'darkgrey' : '#bdbdbd')};
      }
    }
  }
`;

const Thumbs = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  > .MuiSvgIcon-root {
    font-size: 20px;
    color: gray;
  }
`;

const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
  > img {
    display: block;
    width: auto;
    height: 100%;
  }
`;

const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const RejectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  > div:nth-child(1) {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
    justify-content: space-between;

    > span:first-child {
      font-size: 14px;
      margin-bottom: 10px;
    }
    > div {
      display: flex;
      background-color: darkgray;
      border-radius: 4px;
      padding: 1px 10px;
      margin-left: 10px;
      cursor: pointer;

      > span {
        color: white;
        font-size: 12px;
      }

      &:hover {
        background-color: ${(props) => props.theme.orangeColor};
      }
    }
  }

  > div:nth-child(2) {
    > div {
      display: grid;
      grid-template-columns: 40px 1fr 200px;
      grid-column-gap: 20px;
      background-color: whitesmoke;
      margin-bottom: 5px;
      padding: 3px 10px;
      border-radius: 4px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      > span {
        font-size: 13px;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        background-color: darkgrey;

        > span {
          color: white;
        }
      }
    }
  }

  > span {
    font-size: 12px;
    font-weight: 300;
    margin-left: 10px;
  }
`;

const Archivos = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > span {
    font-size: 14px;
    margin: 15px 0;
  }

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: grid;
      grid-template-columns: 40px 1fr 100px;
      justify-content: space-between;
      align-items: center;
      background-color: whitesmoke;
      margin-bottom: 5px;
      padding: 3px 10px;
      border-radius: 4px;
      transition: all ease-in-out 0.2s;

      > div:nth-child(1) {
        display: flex;

        > span {
          font-size: 14px;
          font-weight: 300;
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;

        > span {
          font-size: 14px;
          font-weight: 300;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > .MuiSvgIcon-root {
        opacity: 0;
        font-size: 20px;
        color: grey;
      }

      > div:nth-child(3) {
        display: flex;
        justify-content: center;
        opacity: 0;
        font-size: 20px;
        background-color: white;
        border-radius: 4px;
        padding: 2px 10px;
        transition: all ease-in-out 0.2s;
        cursor: pointer;

        > span {
          font-size: 10px;
          color: grey;
        }
      }

      &:hover {
        background-color: darkgrey;
        transition: all ease-in-out 0.2s;

        div {
          > span {
            color: white;
          }
        }
        div:last-child {
          opacity: 1;
          > span {
            color: dimgray;
          }
        }
      }
    }
  }
`;

const IndexContainer = styled.div`
  display: flex;
  align-items: flex-start;

  > span {
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
  }
`;
