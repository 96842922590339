import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFunctions, httpsCallable } from 'firebase/functions';

import ModalContainer from '../generales/ModalContainer';
import LoadingScreen from '../generales/LoadingScreen';

export default function LicitacionesDetailSendModal({ open, onClose, licitacion, forceReload }) {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const licitaciones_send_oncall = httpsCallable(functions, 'licitaciones_send_oncall');
      await licitaciones_send_oncall({
        idProject: licitacion.idProject,
        idLicitacion: licitacion.idLicitacion,
      });
      setTimeout(() => {
        forceReload();
        onClose();
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={intl.formatMessage({ id: 'app.important', defaultMessage: '' })}
      maxWidth={'400px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.send', defaultMessage: '' }),
          isValid: loading ? false : true,
          onClick: loading ? null : submitHandler,
        },
      ]}
    >
      {loading ? (
        <ContentContainer>
          <LoadingScreen text={`${intl.formatMessage({ id: 'app.sending' })}...`} />
        </ContentContainer>
      ) : (
        <ContentContainer>
          <InputContainer>
            {/* <span>
              <FormattedMessage id="app.licitaciones.sendPopupMessageA" defaultMessage={''} />
            </span> */}
            <span>
              <FormattedMessage id="app.licitaciones.sendPopupMessageA" defaultMessage={''} />.
            </span>
            <span>
              <FormattedMessage id="app.licitaciones.sendPopupMessageB" defaultMessage={''} />.
            </span>
            <span>
              <FormattedMessage id="app.licitaciones.sendPopupMessageC" defaultMessage={''} />.
            </span>
          </InputContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  > span {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  > span:last-child {
    margin-bottom: 0px;
  }
`;
