import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectUser } from '../../features/user/userSlice';
import { nameHandler } from '../../helpers/openFileHandler';
import { logAnalytics } from '../../helpers/logAnalytics';
import LoadingScreen from '../generales/LoadingScreen';
import ModalContainer from '../generales/ModalContainer';

export default function LicitacionesDetailMenuBDownloadModal({
  open,
  onClose,
  folderName,
  selected,
  filesData,
  foldersData,
}) {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const addToZipFolder = async (idFolder, files, folders, jszip, nested) => {
    //primero creamos el folder del cual se origina la solicitud
    jszip.folder(nested);
    //luego revisamos los archivos que estan dentro de este folder
    const filesFiltered = files.filter((x) => x.idFolder === idFolder);
    const foldersFiltered = folders.filter((x) => x.parentId === idFolder);
    const downloadedFiles = await Promise.all(
      filesFiltered.map((x) => fetch(x.uri).then((res) => res.blob()))
    );
    if (nested) {
      downloadedFiles.forEach((file, i) =>
        jszip.folder(nested).file(nameHandler(filesFiltered[i]), file)
      );
    } else {
      downloadedFiles.forEach((file, i) => jszip.file(nameHandler(filesFiltered[i]), file));
    }
    //volvemos a iterar con los folders dentro de este folder
    const promises = [];
    foldersFiltered.forEach((x) => {
      promises.push(
        addToZipFolder(x.idFolder, files, folders, jszip, nested ? `${nested}/${x.name}` : x.name)
      );
    });
    await Promise.all(promises);
  };

  const addToZipFile = async (idFile, files, jszip) => {
    const filesFiltered = files.find((x) => x.idFile === idFile);
    const downloadedFile = await fetch(filesFiltered.uri).then((res) => res.blob());
    jszip.file(nameHandler(filesFiltered), downloadedFile);
  };

  const submit = async () => {
    setLoading(true);
    logAnalytics({
      user,
      path: `users/${user.userId}/module_analytics`,
      type: 'files',
      otherProps: {
        action: 'onClick',
        actionType: 'donwload',
        idLicitacion: location.pathname.split('/')[2],
        folderName,
      },
    });
    try {
      // para cada elemento seleccionado agregamos al ZIP
      const jszip = new JSZip();
      const promises = [];
      (selected?.files || []).forEach((x) => {
        promises.push(addToZipFile(x, filesData, jszip));
      });
      (selected?.folders || []).forEach((x) => {
        promises.push(addToZipFolder(x.idFolder, filesData, foldersData, jszip, x.name));
      });
      await Promise.all(promises);

      // generamos el zip y lo guardamos
      const content = await jszip.generateAsync({ type: 'blob' });
      saveAs(content, `${folderName}.zip`);
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({
        id: 'app.download',
        defaultMessage: 'Download',
      })} ${intl.formatMessage({
        id: 'app.folder',
        defaultMessage: 'Folder',
      })}`}
      buttonsArray={[
        {
          name: loading
            ? `${intl.formatMessage({ id: 'app.loading', defaultMessage: '' })}...`
            : intl.formatMessage({ id: 'app.download', defaultMessage: '' }),
          isValid: !loading,
          onClick: !loading ? submit : null,
        },
      ]}
      maxWidth={'500px'}
    >
      <ContentContainer>
        {loading && (
          <LoadingScreen
            text={`${intl.formatMessage({ id: 'app.downloading', defaultMessage: '' })}...`}
          />
        )}
        {!loading && (
          <span>
            <FormattedMessage id="app.files.textJ" defaultMessage={'Download files'} />
          </span>
        )}
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 10px;

  > span {
    font-size: 14px;
    font-weight: 300;
  }
`;
