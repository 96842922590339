const guidFun = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export const guid = () => {
  let guid = (
    guidFun() +
    guidFun() +
    '-' +
    guidFun() +
    '-4' +
    guidFun().substring(0, 3) +
    '-' +
    guidFun() +
    '-' +
    guidFun() +
    guidFun() +
    guidFun()
  )
    .toLowerCase()
    .toString();
  return guid;
};
