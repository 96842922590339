import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendPasswordResetEmail } from 'firebase/auth';

import { FormInputText } from '../forms/FormInputText';
import LoadingScreen from '../generales/LoadingScreen';

import ForgotPasswordImage from '../../images/login/undraw_forgot_password_re_hxwm.svg';

const ModalResetPassword = ({ open, onClose, email, auth }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [enviado, setEnviado] = useState(false);

  const defaultValues = {
    email: email ? email : '',
  };

  const methods = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  const { handleSubmit, reset, control, setValue, formState, watch } = methods;

  const sendEmailVerification = async (data) => {
    setLoading(true);
    sendPasswordResetEmail(auth, data.email)
      .then(function () {
        setLoading(false);
        setEnviado(true);
      })
      .catch(function (err) {
        Sentry.captureException(err, (scope) => {
          scope.setTag('email', data.email);
          scope.setTag('err.code', err.code);
        });
        Sentry.captureMessage(`Error Code:${err.code}. Email: ${data.email}`, 'log');
        alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
        console.log(err);
        setLoading(false);
        setEnviado(false);
      });
  };

  const closeHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      disableEscapeKeyDown={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open} style={{ outline: 0 }}>
        <Container>
          <img src={ForgotPasswordImage} alt="" />
          <Title>
            <FormattedMessage id="app.login.reset" defaultMessage={''} />
          </Title>
          {loading && (
            <LoadingScreen
              text={intl.formatMessage({ id: 'app.login.modalloading', defaultMessage: '' })}
            />
          )}
          {!loading && enviado && (
            <EnviadoContainer>
              <span>
                <FormattedMessage id="app.login.modalsentmail" defaultMessage={''} />
              </span>
              <ButtonContainer isValid={true} onClick={onClose}>
                <span>
                  <FormattedMessage id="app.login.modalclose" defaultMessage={''} />
                </span>
              </ButtonContainer>
            </EnviadoContainer>
          )}
          {!loading && !enviado && (
            <div>
              <Text>
                <FormattedMessage id="app.login.modalsubtitle" defaultMessage={''} />
              </Text>
              <FormInputContainer>
                <FormInputText
                  name={'email'}
                  control={control}
                  label={'Email'}
                  emailPattern={true}
                  required={true}
                />
              </FormInputContainer>

              <div>
                <ButtonContainer
                  isValid={formState.isValid}
                  onClick={
                    loading || !formState.isValid ? null : handleSubmit(sendEmailVerification)
                  }
                >
                  {!loading && (
                    <span>
                      <FormattedMessage id="app.login.reset" defaultMessage={''} />
                    </span>
                  )}
                </ButtonContainer>
              </div>
            </div>
          )}
          <CloseIconContainer onClick={onClose}>
            <CancelIcon />
          </CloseIconContainer>
        </Container>
      </Fade>
    </Modal>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.theme.name === 'light' ? 'white' : 'black')};
  width: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  overflow-y: scroll;
  max-height: 70%;
  outline: 0;

  > img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: 30px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;

      > div:last-child {
        margin-left: 20px;
      }
    }
  }

  @media (max-width: 760px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled.span`
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  margin: 30px 0 20px 0;
`;

const Text = styled.span`
  /* text-align: center; */
  font-size: 16px;
  font-weight: 300;
`;

const FormInputContainer = styled.div`
  display: flex;
  width: 80%;
  margin-top: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0 10px 0;
  background-color: ${(props) =>
    props.isValid
      ? props.theme.name === 'light'
        ? props.theme.cincoColor
        : 'white'
      : props.theme.name === 'light'
      ? 'lightgrey'
      : 'dimgrey'};
  padding: 5px 30px;
  border-radius: 8px;
  cursor: pointer;

  > span {
    color: ${(props) => (props.theme.name === 'light' ? 'white' : 'grey')};
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      color: white;
      margin-left: 10px;
    }
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  > .MuiSvgIcon-root {
    font-size: 30px;
    color: ${(props) => (props.theme.name === 'light' ? 'grey' : 'lightgrey')};
  }
`;

const EnviadoContainer = styled.div`
  > span {
    margin: 30px 0;
    font-size: 25px;
    font-weight: 500;
    color: ${(props) => (props.theme.name === 'light' ? props.theme.cincoColor : 'white')};
  }
`;

export default ModalResetPassword;
