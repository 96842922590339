import { convertTZ } from './convertTZ';

export const firestoreDateFiles = (timestamp, file) => {
  if (timestamp) {
    const fireStoreDate = convertTZ(new Date(timestamp._seconds * 1000), 'America/Santiago');

    const day =
      fireStoreDate.getDate() < 10 ? `0${fireStoreDate.getDate()}` : fireStoreDate.getDate();
    const month =
      fireStoreDate.getMonth() + 1 < 10
        ? `0${fireStoreDate.getMonth() + 1}`
        : fireStoreDate.getMonth() + 1;
    const year = fireStoreDate.getFullYear();
    const hours =
      fireStoreDate.getHours() < 10 ? `0${fireStoreDate.getHours()}` : fireStoreDate.getHours();
    const minutes =
      fireStoreDate.getMinutes() < 10
        ? `0${fireStoreDate.getMinutes()}`
        : fireStoreDate.getMinutes();

    const when = formatDate(fireStoreDate);
    if (when) {
      return `${when} ${hours}:${minutes}`;
    } else {
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  } else {
    return `00-00-0000 00:00`;
  }
};

// https://stackoverflow.com/questions/32199998/check-if-date-is-today-was-yesterday-or-was-in-the-last-7-days
const formatDate = (dt) => {
  const date = dt.getDate(),
    diffDays = new Date().getDate() - date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return 'hoy';
  } else if (diffYears === 0 && diffMonths === 0 && diffDays === 1) {
    return 'ayer';
  } else if (diffYears === 0 && diffMonths === 0 && diffDays === 2) {
    return 'anteayer';
  } else {
    return false;
  }
};
