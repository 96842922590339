import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function IntlSelector({ locale, setLocale }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={locale === 'es' ? 'Idioma' : 'Language'}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <span style={{ fontSize: '14px' }}>{locale.toUpperCase()}</span>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setLocale('es')}>
          <Avatar sx={{ width: 32, height: 32 }}>
            <span style={{ fontSize: '14px' }}>ES</span>
          </Avatar>{' '}
          <span style={{ fontSize: '14px' }}>{locale === 'es' ? 'Español' : 'Spanish'}</span>
        </MenuItem>
        <MenuItem onClick={() => setLocale('en')}>
          <Avatar sx={{ width: 32, height: 32 }}>
            <span style={{ fontSize: '14px' }}>ES</span>
          </Avatar>{' '}
          <span style={{ fontSize: '14px' }}>{locale === 'es' ? 'Inglés' : 'English'}</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
