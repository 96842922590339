import React from 'react';
import styled from 'styled-components';

import ModalContainer from '../generales/ModalContainer';

export default function NoViewerModal({ open, onClose, url, fileName, idFile }) {
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={fileName}
      maxHeight={'100vh - 100px'}
      maxWidth={'100%'}
      downloadUrl={url}
    >
      <Container>
        <span>No se han generado vistas del archivo</span>
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  > .MuiSvgIcon-root {
    font-size: 40px;
    color: grey;
  }

  > span {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 300;
  }
`;
