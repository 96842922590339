import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import ModalContainer from '../generales/ModalContainer';
import { getFunctions, httpsCallable } from 'firebase/functions';

export default function LicitacionesDetailMenuCQaDeleteModal({
  open,
  onClose,
  onCloseParent,
  qa,
  licitacion,
  forceReload,
}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    try {
      setLoading(true);
      const functions = getFunctions();
      const licitaciones_question_oncall = httpsCallable(functions, 'licitaciones_question_oncall');
      await licitaciones_question_oncall({
        idProject: licitacion.idProject,
        idLicitacion: licitacion.idLicitacion,
        idQa: qa.idQa,
        update: 'delete',
      });
      setTimeout(() => {
        forceReload();
        onCloseParent();
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({ id: 'app.delete' })} ${intl.formatMessage({
        id: 'app.question',
      })}`}
      maxWidth={'400px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.delete', defaultMessage: '' }),
          isValid: true,
          onClick: () => deleteHandler(),
          loading,
        },
      ]}
    >
      <ContentContainer>
        <span>
          <FormattedMessage id="app.licitaciones.detailMenuCa" defaultMessage="" />
        </span>
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px;

  > span {
    font-size: 14px;
    font-weight: 300;
  }
`;
