import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';

import { selectUser } from './features/user/userSlice';
import { darkTheme, lightTheme, GlobalStyles } from './constants/theme';
import esMessages from './locales/es.json';
import enMessages from './locales/en.json';
import { selectTheme } from './features/theme/themeSlice';
import Login from './components/acceso/Login';
import Home from './components/navegacion/Home';

function App() {
  const user = useSelector(selectUser);
  const theme = useSelector(selectTheme);

  const lang = navigator.language || navigator.userLangauge;
  const langLocale = lang.includes('en') ? 'en' : 'es';

  const [locale, setLocale] = useState(user?.locale || langLocale);

  if (!user) {
    window.Intercom('shutdown');
  }
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'b7b3qbqu',
    custom_launcher_selector: '#support',
    language_override: locale,
  });

  useEffect(() => {
    if (user?.locale) {
      setLocale(user?.locale);
    }
  }, [user?.locale]);

  return (
    <IntlProvider messages={locale === 'es' ? esMessages : enMessages} key={locale} locale={locale}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
          {!user ? (
            <Login locale={locale} setLocale={(x) => setLocale(x)} />
          ) : (
            <Home locale={locale} />
          )}
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
