import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../features/theme/themeSlice';
import ListSubheader from '@mui/material/ListSubheader';

const BootstrapInput = styled(InputBase)(({ selecttheme, padding }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: selecttheme === 'light' ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
    border: '1px solid #ced4da',
    fontSize: 14,
    fontWeight: 300,
    padding: padding ? padding : '10px 26px 10px 12px',
    color: selecttheme === 'light' ? 'grey' : 'white',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default function SelectComponent({
  value,
  changeHandle,
  items,
  sectionItems,
  disabled,
  none,
  selectNone,
  padding,
  inputLabel,
  marginBottom,
  displayProps,
}) {
  const theme = useSelector(selectTheme);

  const arrayItems = items || [];
  const arraySectionItems = sectionItems || [];

  const renderGroup = (section) => {
    const items = section.items.map((x) => {
      return (
        <MenuItem key={x.id} value={x.id}>
          {x.label}
        </MenuItem>
      );
    });
    return [
      <ListSubheader style={{ textDecoration: 'underline' }}>{section.label}</ListSubheader>,
      items,
    ];
  };

  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 120,
        maxWidth: 1024,
        width: '100%',
        margin: marginBottom ? `0 0 ${marginBottom} 0` : '0 0 10px 0',
      }}
      disabled={disabled}
    >
      <Select
        value={value}
        onChange={changeHandle}
        size={'small'}
        input={<BootstrapInput selecttheme={theme} padding={padding} marginBottom={marginBottom} />}
        displayEmpty={true}
        SelectDisplayProps={displayProps || { style: { paddingBottom: 8, paddingTop: 8 } }}
      >
        {!selectNone && (
          <MenuItem value="" disabled selected hidden>
            <em>{none}</em>
          </MenuItem>
        )}
        {selectNone && (
          <MenuItem value="" selected>
            <em>{none}</em>
          </MenuItem>
        )}
        {arrayItems.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            {value.label}
          </MenuItem>
        ))}
        {arraySectionItems.map((section) => renderGroup(section))}
      </Select>
    </FormControl>
  );
}
