import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appConfig: [],
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppConfig: (state, action) => {
      state.appConfig = action.payload;
    },
  },
});

export const { setAppConfig } = appConfigSlice.actions;

export const selectAppConfig = (state) => state.appConfig.appConfig;

export default appConfigSlice.reducer;
