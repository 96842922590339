import { createGlobalStyle } from 'styled-components';

export const darkTheme = {
  name: 'dark',
  body: '#000',
  textColor: '#fff',
  aTag: '#fff',
  headerColor: '#000',
  sidebarColor: '#262626',
  primaryColor: 'black',
  draft: '#af8c64',
};

export const lightTheme = {
  name: 'light',
  body: 'whitesmoke',
  textColor: 'black',
  aTag: 'dimgrey',
  headerColor: '#192685',
  sidebarColor: '#394596',
  primaryColor: '#192685', //'rgba(25, 38, 133, 1)'
  secondaryColor: '#b9c3df', //'rgb(185,195,223)'
  dosColor: '#394596',
  tresColor: '#5A64A7',
  cuatroColor: '#5A64A7',
  cincoColor: '#7B83B9',
  seisColor: '#9CA2CA',
  sieteColor: '#BDC1DC',
  ochoColor: '#DEE0ED',
  nueveColor: '#EAEBF4',
  draft: '#ffe0b5',
  orangeColor: '#ffaa33',
  bgGray100: 'rgba(243, 244, 246)',
};

export const GlobalStyles = createGlobalStyle`
* {
  font-family: "Inter", sans-serif;
}
 body {
  background: ${(props) => props.theme.body};
  color: ${(props) => props.theme.textColor};
  transition: .3s ease;
 }

 h2 {
   color: ${(props) => props.theme.headingColor};
 }

 a {
   color: ${(props) => props.theme.aTag};
 }

 input,
select,
textarea {
  font-family: "Inter", sans-serif;
}
`;
