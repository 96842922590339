import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import HomeIcon from '@mui/icons-material/Home';

import { selectUser } from '../../features/user/userSlice';
import useWindowDimensions from '../../helpers/windowDimensions';

import LogoBlanco2 from '../../images/login/LogoBlanco2.png';

export default function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = location.pathname.split('/').length === 2;

  const user = useSelector(selectUser);
  const { width } = useWindowDimensions();

  const [showSearch, setShowSearch] = useState(false);

  const perfilHandler = () => {
    navigate('/perfil');
  };

  return (
    <Container isHome={isHome} showSearch={showSearch}>
      <LeftContainer>
        <img src={LogoBlanco2} alt="nupavLogo" />
      </LeftContainer>

      <RightContainer>
        <UserContainer>
          <UserName>{user.name}</UserName>
          <UserPosition>{user.email}</UserPosition>
        </UserContainer>
        <AvatarLogo onClick={perfilHandler}>{user.name.substring(0, 2).toUpperCase()}</AvatarLogo>
        <VerticalLine show={width > 500} />
        <HomeIcon onClick={() => navigate('/')} />
        {!location.pathname.includes('perfil') && width <= 500 && (
          <SearchIcon onClick={() => setShowSearch(!showSearch)} />
        )}
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  width: calc(100% - 10px);
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.headerColor};
  padding: 0 10px 0 10px;
  height: 50px;
  z-index: 998;
  border-radius: 8px;
  margin: 5px 5px 0 5px;

  @media (max-width: 500px) {
    margin: ${(props) => (props.showSearch ? '0 0 55px 0' : 0)};
    width: 100%;
    border-radius: 0px;
    transition: ${(props) =>
      props.showSearch ? 'all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8)' : 'all 0.3s ease-in-out'};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  height: 65px;

  > .MuiSvgIcon-root {
    display: none;
    color: gray;
    @media (max-width: 500px) {
      display: flex;
      color: white;
      font-size: 25px;
    }
  }

  > img {
    object-fit: contain;
    height: 50px;

    @media (max-width: 768px) {
      height: 50px;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding-right: 0px;
  }

  > .MuiSvgIcon-root {
    color: white;
    font-size: 36px;
    margin-left: 5px;
    border-radius: 18px;
    /* width: 30px;
    height: 30px; */
    padding: 3px;
    cursor: pointer;

    &:hover {
      background-color: white;
      color: ${(props) => props.theme.primaryColor};
      transition: ease-in 0.2s;
    }

    @media (max-width: 768px) {
      margin-left: 4px;
    }

    @media (max-width: 500px) {
      margin-left: 10px;

      &:hover {
        background-color: transparent;
        color: white;
      }
    }
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  cursor: pointer;
`;

const UserName = styled.span`
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  color: white;

  @media (max-width: 768px) {
    display: none;
  }
`;

const UserPosition = styled.span`
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: rgba(255, 255, 255, 0.7);

  @media (max-width: 768px) {
    display: none;
  }
`;

const VerticalLine = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  width: 1px;
  height: 33px;
  background-color: white;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin: 0 5px 0 20px;
  }
`;

const AvatarLogo = styled(Avatar)`
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
  font-size: 12px !important;

  @media (max-width: 768px) {
    width: 25px !important;
    height: 25px !important;
  }
`;
