import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import { firestoreDate } from '../../helpers/firestoreDate';
import LoadingScreen from '../generales/LoadingScreen';
import Countdown from '../generales/CuentaRegresiva';
import PageLoadingError from '../generales/PageLoadingError';
import LicitacionesDetailMenuAGantt from './Licitaciones_detail_menuA_Gantt';
import { convertTZ } from '../../helpers/convertTZ';
import { differenceDays } from '../../helpers/differenceDays';

export default function LicitacionesDetailMenuA({ licitacion, loading, error, stage, stageDates }) {
  const intl = useIntl();
  const [ganttDataReady, setGanttDataReady] = useState(false);
  const [ganttData, setGanttData] = useState([]);

  // console.log({ stageDates, stage });

  const categoriasOrdered = [];
  [...(licitacion?.secciones || []), { label: 'Sin Sección' }].forEach((x) => {
    if (!x.id) {
      categoriasOrdered.push({
        sectionName: x.label,
        data: (licitacion?.categorias || []).filter((y) => !y.section || y.section === ''),
      });
    } else {
      categoriasOrdered.push({
        sectionName: x.label,
        data: (licitacion.categorias || []).filter((y) => y.section === x.id),
      });
    }
  });

  useEffect(() => {
    if (
      (Object.entries(stageDates) || []).length > 0 &&
      (stageDates?.idStage === stage?.id || (stageDates?.idStage === 'stage1' && !stage))
    ) {
      const ganttDataB = [];
      ganttDataB.push({
        taskId: stage?.id || 'stage1',
        taskName: '',
        stageName: `${intl.formatMessage({ id: 'app.stage' })} ${stage?.value || 1}`,
        stageNameL: '',
        status: { backgroundColor: 'rgba(0,0,0,0.1)', color: 'black', label: '' },
      });
      // Visita a Terreno
      if (stageDates?.visitaTerrenoStart && stageDates?.visitaTerrenoEnd) {
        const duration = differenceDays(
          new Date(stageDates?.visitaTerrenoEnd._seconds * 1000),
          new Date(stageDates?.visitaTerrenoStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task1`,
          taskName: duration < 10 ? 'Visita a Terreno' : '',
          stageName: duration < 10 ? '' : 'Visita a Terreno',
          stageNameL: '',
          startDate: convertTZ(
            new Date(stageDates?.visitaTerrenoStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(
            new Date(stageDates?.visitaTerrenoEnd._seconds * 1000),
            'America/Santiago'
          ),
          parentId: stage?.id || 'stage1',
        });
      }
      // Entrega de Antecedentes
      if (stageDates?.entregaAntecedentesStart && stageDates?.entregaAntecedentesEnd) {
        const duration = differenceDays(
          new Date(stageDates?.entregaAntecedentesEnd._seconds * 1000),
          new Date(stageDates?.entregaAntecedentesStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task2`,
          taskName: duration < 10 ? 'Entrega de Antecedentes' : '',
          stageName: duration < 10 ? '' : 'Entrega de Antecedentes',
          stageNameL: '',
          startDate: convertTZ(
            new Date(stageDates?.entregaAntecedentesStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(
            new Date(stageDates?.entregaAntecedentesEnd._seconds * 1000),
            'America/Santiago'
          ),
          parentId: stage?.id || 'stage1',
        });
      }
      //Estudio de Propuesta
      if (stageDates?.estudioStart && stageDates?.estudioEnd) {
        const duration = differenceDays(
          new Date(stageDates?.estudioEnd._seconds * 1000),
          new Date(stageDates?.estudioStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task3`,
          taskName: duration < 10 ? 'Estudio de Propuesta' : '',
          stageName: duration < 10 ? '' : 'Estudio de Propuesta',
          stageNameL: '',
          startDate: convertTZ(
            new Date(stageDates?.estudioStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(new Date(stageDates?.estudioEnd._seconds * 1000), 'America/Santiago'),
          parentId: stage?.id || 'stage1',
        });
      }
      //Consultas
      if (stageDates?.questionStart && stageDates?.questionEnd) {
        const duration = differenceDays(
          new Date(stageDates?.questionEnd._seconds * 1000),
          new Date(stageDates?.questionStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task4`,
          taskName: duration < 10 ? 'Consultas' : '',
          stageName: duration < 10 ? '' : 'Consultas',
          stageNameL: '',
          startDate: convertTZ(
            new Date(stageDates?.questionStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(new Date(stageDates?.questionEnd._seconds * 1000), 'America/Santiago'),
          parentId: stage?.id || 'stage1',
        });
      }
      // Respuestas
      if (stageDates?.answerStart && stageDates?.answerEnd) {
        const duration = differenceDays(
          new Date(stageDates?.answerEnd._seconds * 1000),
          new Date(stageDates?.answerStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task5`,
          taskName: duration < 10 ? 'Respuestas' : '',
          stageName: duration < 10 ? '' : 'Respuestas',
          stageNameL: '',
          startDate: convertTZ(
            new Date(stageDates?.answerStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(new Date(stageDates?.answerEnd._seconds * 1000), 'America/Santiago'),
          parentId: stage?.id || 'stage1',
        });
      }
      // Recepción de Ofertas
      if (stageDates?.offerStart && stageDates?.offerEnd) {
        const duration = differenceDays(
          new Date(stageDates?.offerEnd._seconds * 1000),
          new Date(stageDates?.offerStart._seconds * 1000)
        );
        ganttDataB.push({
          taskId: `task6`,
          taskName: '',
          stageName: duration < 10 ? '' : 'Recepción de Ofertas',
          stageNameL: duration < 10 ? 'Recepción de Ofertas' : '',
          startDate: convertTZ(
            new Date(stageDates?.offerStart._seconds * 1000),
            'America/Santiago'
          ),
          endDate: convertTZ(new Date(stageDates?.offerEnd._seconds * 1000), 'America/Santiago'),
          parentId: stage?.id || 'stage1',
        });
      }
      setGanttData(ganttDataB);
      setGanttDataReady(true);
    }
  }, [stageDates, stage]);

  // console.log({ ganttData });

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.licitaciones.configTitleA" defaultMessage={''} />
          </span>
          <InformationContainer>
            <div>
              <span>
                <FormattedMessage id="app.title" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.name}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.description" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.description || '--'}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configInfA" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.instructions || '--'}</span>
              </div>
            </div>
            <div>
              <span>
                <FormattedMessage id="app.contact" defaultMessage={''} />
              </span>
              <div>
                <span>{licitacion.contactName}</span>
              </div>
            </div>
            <div>
              <span></span>
              <div>
                <span>{licitacion.contactEmail}</span>
              </div>
            </div>
          </InformationContainer>
        </DataContainer>
      </InputContainer>
      {/* <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.stages" defaultMessage={''} />
          </span>
          <InformationContainer>
            <div>
              <span>
                <FormattedMessage id="app.stage" defaultMessage={''} /> 1
              </span>
              <div>
                <span>
                  <FormattedMessage id="app.licitaciones.info" defaultMessage={''} />
                </span>
                {!licitacion?.stageActive && (
                  <div>
                    <span>
                      <FormattedMessage id="app.licitaciones.infoB" />
                    </span>
                  </div>
                )}
              </div>
            </div>
            {orderBy([...(licitacion?.stages || [])], 'value').map((x) => (
              <div key={x.value}>
                <span>
                  <FormattedMessage id="app.stage" defaultMessage={''} /> {x.value}
                </span>
                <div>
                  <span>{x.description}</span>
                  {licitacion?.stageActive === x.id && (
                    <div>
                      <span>
                        <FormattedMessage id="app.licitaciones.infoB" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </InformationContainer>
        </DataContainer>
      </InputContainer> */}
      {/* <GanttContainer className={'gantt-container'}>
        <LicitacionesDetailMenuAGantt ganttData={ganttData} />
      </GanttContainer> */}
      <InputContainer complete={true}>
        <DataContainer italic={true}>
          <span>
            <FormattedMessage id="app.licitaciones.configTitleB" defaultMessage={''} />
          </span>
          <DateTimeContainer>
            <div>
              <span>
                <FormattedMessage id="app.event" />
              </span>
              <span>
                <FormattedMessage id="app.start" />
              </span>
              <span>
                <FormattedMessage id="app.finish" />
              </span>
              <span>
                <FormattedMessage id="app.ends" />
              </span>
            </div>

            {stageDates.visitaTerrenoStart && stageDates.visitaTerrenoEnd && (
              <div>
                <span>
                  <FormattedMessage id="app.licitaciones.configDateTimeI" />
                </span>
                <span>
                  {stageDates.visitaTerrenoStart
                    ? firestoreDate(stageDates.visitaTerrenoStart, true, intl.locale)
                    : '--'}
                </span>
                <span>
                  {stageDates.visitaTerrenoEnd
                    ? firestoreDate(stageDates.visitaTerrenoEnd, true, intl.locale)
                    : '--'}
                </span>
                {stageDates.visitaTerrenoEnd ? (
                  <Countdown targetDate={stageDates.visitaTerrenoEnd} />
                ) : (
                  <span>--</span>
                )}
              </div>
            )}
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeJ" />
              </span>
              <span>
                {stageDates.entregaAntecedentesStart
                  ? firestoreDate(stageDates.entregaAntecedentesStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {stageDates.entregaAntecedentesEnd
                  ? firestoreDate(stageDates.entregaAntecedentesEnd, true, intl.locale)
                  : '--'}
              </span>
              {stageDates.entregaAntecedentesEnd ? (
                <Countdown targetDate={stageDates.entregaAntecedentesEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeK" />
              </span>
              <span>
                {stageDates.estudioStart
                  ? firestoreDate(stageDates.estudioStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {stageDates.estudioEnd
                  ? firestoreDate(stageDates.estudioEnd, true, intl.locale)
                  : '--'}
              </span>
              {stageDates.estudioEnd ? (
                <Countdown targetDate={stageDates.estudioEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeL" />
              </span>
              <span>
                {stageDates.questionStart
                  ? firestoreDate(stageDates.questionStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {stageDates.questionEnd
                  ? firestoreDate(stageDates.questionEnd, true, intl.locale)
                  : '--'}
              </span>
              {stageDates.questionEnd ? (
                <Countdown targetDate={stageDates.questionEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeM" />
              </span>
              <span>
                {stageDates.answerStart
                  ? firestoreDate(stageDates.answerStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {stageDates.answerEnd
                  ? firestoreDate(stageDates.answerEnd, true, intl.locale)
                  : '--'}
              </span>
              {stageDates.answerEnd ? (
                <Countdown targetDate={stageDates.answerEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeH" />
              </span>
              <span>
                {stageDates.offerStart
                  ? firestoreDate(stageDates.offerStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {stageDates.offerEnd ? firestoreDate(stageDates.offerEnd, true, intl.locale) : '--'}
              </span>
              {stageDates.offerEnd ? (
                <Countdown targetDate={stageDates.offerEnd} />
              ) : (
                <span>--</span>
              )}
            </div>
            {/* <div>
              <span>
                <FormattedMessage id="app.licitaciones.configDateTimeN" />
              </span>
              <span>
                {licitacion.adjudicacionStartNull
                  ? firestoreDate(licitacion.adjudicacionStart, true, intl.locale)
                  : '--'}
              </span>
              <span>
                {licitacion.adjudicacionEndNull
                  ? firestoreDate(licitacion.adjudicacionEnd, true, intl.locale)
                  : '--'}
              </span>
              {licitacion.adjudicacionEndNull ? (
                <Countdown targetDate={licitacion.adjudicacionEnd} />
              ) : (
                <span>--</span>
              )}
            </div> */}
          </DateTimeContainer>
        </DataContainer>
      </InputContainer>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  > span:nth-child(1) {
    font-size: 14px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.cincoColor};
    font-weight: 500;
  }
  > span:nth-child(2) {
    font-size: 14px;
    font-weight: 300;
    font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  }
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid whitesmoke;
    > span {
      font-size: 14px;
      font-weight: 300;
    }
    > div {
      display: flex;
      > span {
        font-size: 14px;
        font-weight: 300;
      }
      > div {
        display: flex;
        align-items: center;
        background-color: limegreen;
        border-radius: 4px;
        padding: 0 5px;
        margin-left: 15px;

        > span {
          color: white;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }

  > div:nth-child(4) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  > div:nth-child(5) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  > div:nth-child(8) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid whitesmoke;
    > span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  > div:nth-child(1) {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 5px;
    padding-bottom: 5px;

    > span {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

const GanttContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 390px;
`;
