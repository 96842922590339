import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CircularProgress } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { downloadUrlFile } from '../../helpers/downloadUrlFile';

export default function ModalContainer({
  children,
  open,
  onClose,
  title,
  deleteHandler,
  buttonsArray,
  width,
  maxWidth,
  maxHeight,
  downloadUrl,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableEnforceFocus
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open} style={{ outline: 0 }}>
        <Container width={width} maxWidth={maxWidth} maxHeight={maxHeight}>
          <Title>
            <span>{title}</span>
          </Title>
          <ContentContainer>{children}</ContentContainer>
          {(buttonsArray || deleteHandler) && (
            <ButtonsContainer>
              {deleteHandler && (
                <DeleteContainer onClick={deleteHandler}>
                  <DeleteForeverIcon />
                </DeleteContainer>
              )}
              <div>
                {(buttonsArray || []).map((x, index) => (
                  <ButtonContainer
                    key={index}
                    onClick={x.onClick}
                    isValid={x.isValid}
                    loading={x.loading}
                    bgColor={x.bgColor}
                  >
                    {x.loading && <CircularProgress />}
                    <span>{x.name}</span>
                  </ButtonContainer>
                ))}
              </div>
            </ButtonsContainer>
          )}
          {downloadUrl && (
            <DownloadIconContainer
              onClick={() => {
                downloadUrlFile(downloadUrl, title);
                // window.open(downloadUrl);
              }}
            >
              <CloudDownloadIcon />
            </DownloadIconContainer>
          )}
          <CloseIconContainer onClick={onClose}>
            <CloseIcon />
          </CloseIconContainer>
        </Container>
      </Fade>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => (props.theme.name === 'light' ? 'white' : 'dimgray')};
  width: ${(props) => (props.width ? props.width : '70%')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '800px')};
  border-radius: 4px;
  overflow-y: auto;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '70%')};
  outline: 0;

  @media (max-width: 500px) {
    width: 90%;
    max-height: 80%;
  }
`;

const Title = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  background-color: whitesmoke;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 80px;

  > span {
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DownloadIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 50px;
  cursor: pointer;

  > .MuiSvgIcon-root {
    font-size: 25px;
    color: darkgrey;
    &:hover {
      color: dimgrey;
    }
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  > .MuiSvgIcon-root {
    font-size: 25px;
    color: darkgrey;
    &:hover {
      color: dimgrey;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 10px 10px 0;
  border-top: 1px solid lightgrey;
  position: relative;
  background-color: whitesmoke;
  height: 45px;
  min-height: 45px;

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: flex-end;
  }

  > div {
    display: flex;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isValid ? (props.bgColor ? props.bgColor : props.theme.primaryColor) : 'lightgrey'};
  padding: 2px 10px;
  border-radius: 4px;
  cursor: ${(props) => (props.isValid ? 'pointer' : 'default')};
  transition: all ease-in-out 0.1s;
  min-width: 100px;
  margin-left: 10px;
  transition: all ease-in-out 0.2s;

  > .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
    color: white;
    margin-right: 10px;
  }

  > span {
    font-size: 14px;
    font-weight: 300;
    color: white;
  }

  &:hover {
    background-color: ${(props) => (props.isValid ? props.theme.cincoColor : 'lightgrey')};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const DeleteContainer = styled.div`
  position: absolute;
  left: 15px;

  > .MuiSvgIcon-root {
    color: lightgrey;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
`;
