import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormattedMessage, useIntl } from 'react-intl';

import LoadingScreen from '../generales/LoadingScreen';

export const SignUpModal = ({ open, onClose, loading, error }) => {
  const intl = useIntl();

  const closeHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      disableEscapeKeyDown={true}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open} style={{ outline: 0 }}>
        <Container>
          {loading && (
            <LoadingScreen
              text={intl.formatMessage({
                id: 'app.login.signUpD',
                defaultMessage: 'Verifying the data...',
              })}
            />
          )}
          {!loading && error && (
            <div>
              <span>
                <FormattedMessage id="app.login.signUpA" defaultMessage={'An error has occurred'} />
              </span>
              <span>
                <FormattedMessage
                  id="app.login.signUpI"
                  defaultMessage={
                    'You may already have an account created. If you forget your password, you can reset it on the home page'
                  }
                />
                .
              </span>
              <span>
                <FormattedMessage
                  id="app.login.signUpB"
                  defaultMessage={
                    'We remind you that the creation of accounts is only enabled for users who have received an invitation to bid on nupav'
                  }
                />
                .
              </span>
              <span>
                <FormattedMessage
                  id="app.login.signUpC"
                  defaultMessage={
                    "Try again, if the problem continues please contact your company's tender manager"
                  }
                />
                .
              </span>
            </div>
          )}
          {!loading && !error && (
            <div>
              <span>
                <FormattedMessage id="app.login.signUpE" defaultMessage={'Email Verification'} />
              </span>
              <span>
                <FormattedMessage
                  id="app.login.signUpF"
                  defaultMessage={'We have sent an email from'}
                />{' '}
                <span style={{ color: 'blue', fontWeight: 400 }}>licitaciones@nupav.com</span>{' '}
                <FormattedMessage
                  id="app.login.signUpG"
                  defaultMessage={'to verify your account'}
                />
                .
              </span>
              <span>
                <FormattedMessage
                  id="app.login.signUpH"
                  defaultMessage={
                    "If it doesn't arrive in your inbox within a few minutes, check your spam folder"
                  }
                />
                .
              </span>
            </div>
          )}
          {!loading && (
            <CloseIconContainer onClick={onClose}>
              <CancelIcon />
            </CloseIconContainer>
          )}
        </Container>
      </Fade>
    </Modal>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  padding: 20px 30px;
  overflow-y: scroll;
  max-height: 70%;
  outline: 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > span {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 15px;
      width: 100%;
    }
    > span:nth-child(1) {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  @media (max-width: 760px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  > .MuiSvgIcon-root {
    font-size: 25px;
    color: grey;
  }

  &:hover {
    color: black;
  }
`;
