import React, { useState } from 'react';
import styled from 'styled-components';
import FolderIcon from '@mui/icons-material/Folder';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FormattedMessage } from 'react-intl';
import DownloadIcon from '@mui/icons-material/Download';

import useWindowDimensions from '../../helpers/windowDimensions';
import { textMiddleEllipsis } from '../../helpers/textMiddleEllipsis';
import LicitacionesDetailMenuBDownloadModal from './Licitaciones_detail_menuB_downloadModal';

export default function LicitacionesDetailMenuBFolder({
  folder,
  setCurrentFolder,
  selected,
  setSelected,
  foldersData,
  filesData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadModal, setDownloadModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };
  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const { width } = useWindowDimensions();

  const onClickHandler = (e) => {
    setSelected({ files: [], folders: [] });
    setCurrentFolder(folder);
    e.stopPropagation();
  };

  const isSelected = selected.folders.find((x) => x.idFolder === folder.idFolder) ? true : false;

  const selectHandler = (e) => {
    if (e.target.tagName === 'DIV') {
      setSelected({
        ...selected,
        folders: isSelected
          ? selected.folders.filter((x) => x.idFolder !== folder.idFolder)
          : [...selected.folders, folder],
      });
    }
  };

  const characters = () => {
    if (width > 1280) return { large: 70, cutOne: 20, cutTwo: 10 };
    if (width > 1024) return { large: 50, cutOne: 20, cutTwo: 10 };
    if (width > 820) return { large: 35, cutOne: 20, cutTwo: 10 };
    if (width > 768) return { large: 35, cutOne: 20, cutTwo: 10 };
    if (width > 500) return { large: 35, cutOne: 20, cutTwo: 10 };
    return { large: 35, cutOne: 20, cutTwo: 10 };
  };

  const char = characters();

  return (
    <>
      <Container selected={isSelected} onClick={selectHandler}>
        <div>
          {isSelected && <CheckBoxIcon />}
          {!isSelected && <CheckBoxOutlineBlankIcon />}
        </div>
        <div>
          <div>
            <FolderIcon />
          </div>
          <span onClick={onClickHandler}>
            {textMiddleEllipsis(folder.name, char.large, char.cutOne, char.cutTwo)}
          </span>
        </div>
        <div>
          <span>--</span>
        </div>

        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            disableAutoFocus
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                setDownloadModal({ open: true });
              }}
            >
              <ListItemIcon>
                <DownloadIcon style={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText>
                <span style={{ fontSize: '14px', fontWeight: '300' }}>
                  <FormattedMessage id="app.download" defaultMessage="" />
                </span>
              </ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </Container>
      {downloadModal.open && (
        <LicitacionesDetailMenuBDownloadModal
          open={downloadModal.open}
          onClose={() => setDownloadModal(false)}
          folderName={folder.name}
          selected={{ folders: [folder], files: [] }}
          foldersData={foldersData}
          filesData={filesData}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 30px 3fr 0.8fr 0.8fr;
  width: 100%;
  height: 39px;
  max-height: 39px;

  @media (max-width: 768px) {
    grid-template-columns: 30px 3.8fr 1.4fr;
  }

  &:hover {
    background-color: whitesmoke;

    > div:nth-child(1) {
      background-color: white;
    }
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      color: ${(props) => (props.selected ? 'black' : 'transparent')};
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    padding-left: 5px;
    border-bottom: ${(props) => (props.selected ? '1px solid lightgrey' : '1px solid #e6e8eb')};
    border-left: ${(props) => (props.selected ? '2px solid #0061fe;' : '2px solid white;')};
    background-color: ${(props) => (props.selected ? 'rgba(0, 97, 254, 0.16)' : 'transparent')};

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      margin-right: 10px;

      > .MuiSvgIcon-root {
        color: rgb(161, 201, 247);
        font-size: 28px;
      }
    }

    > span {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
      color: rgb(30, 25, 25);
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
    border-bottom: ${(props) => (props.selected ? '1px solid lightgrey' : '1px solid #e6e8eb')};
    background-color: ${(props) => (props.selected ? 'rgba(0, 97, 254, 0.16)' : 'transparent')};
    padding-right: 15px;

    @media (max-width: 768px) {
      display: none;
    }

    > span {
      width: 100%;
      font-weight: 300;
      color: rgb(30, 25, 25);
      font-size: 12px;
      text-align: right;
    }
  }

  > div:nth-child(4) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: ${(props) => (props.selected ? '1px solid lightgrey' : '1px solid #e6e8eb')};
    background-color: ${(props) => (props.selected ? 'rgba(0, 97, 254, 0.16)' : 'transparent')};

    > button {
      .MuiSvgIcon-root {
        color: grey;
      }
    }
  }
`;
