import icon_pdf from '../images/archivos/pdf48px.png';
import icon_excel from '../images/archivos/excel48px.png';
import icon_word from '../images/archivos/word48px.png';
import icon_power from '../images/archivos/power48px.png';
import icon_audio from '../images/archivos/audio48px.png';
import icon_video from '../images/archivos/video48px.png';
import icon_autocad from '../images/archivos/autocad512px.png';
import icon_file from '../images/archivos/file48px.png';
import icon_revit from '../images/archivos/revit48px.png';
import icon_naviswork from '../images/archivos/naviswork48px.png';
import icon_project from '../images/archivos/project576px.png';

export const mimeType = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (name) {
    const extension = name.split('.').pop();
    if (extension === 'dwg') return icon_autocad;
    if (extension === 'rvt') return icon_revit;
    if (extension === 'nwd') return icon_naviswork;
    if (extension === 'ifc') return icon_autocad;
    if (extension === 'mpp') return icon_project;
  }
  if (file && file.metadata) {
    if (file?.metadata.includes('image') && file?.uriSmall) return file?.uriSmall;
    if (file?.metadata.includes('image') && file?.uri) return file?.uri;
    if (file?.metadata.includes('audio') && file?.uri) return icon_audio;
    if (file?.metadata.includes('video') && file?.uri) return icon_video;
    if (file?.metadata === 'application/pdf') return icon_pdf;
    if (
      file?.metadata === 'application/vnd.ms-excel' ||
      file?.metadata === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
      return icon_excel;
    if (
      file?.metadata === 'application/msword' ||
      file?.metadata === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
      return icon_word;
    if (
      file?.metadata === 'application/vnd.ms-powerpoint' ||
      file?.metadata === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    )
      return icon_power;
  }
  return icon_file;
};

export const isGoogleDoc = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (
      extension === 'gdoc' ||
      extension === 'gslides' ||
      extension === 'gsheet' ||
      extension === 'gdraw'
    )
      return true;
    return false;
  }
  return false;
};

export const isOfficeDoc = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (
      extension === 'doc' ||
      extension === 'docx' ||
      extension === 'xls' ||
      extension === 'xlsx' ||
      extension === 'ppt' ||
      extension === 'pptx'
    )
      return true;
    return false;
  }
  return false;
};

export const isAutocadDoc = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (
      extension === 'dwg' ||
      extension === 'rvt' ||
      extension === 'nwd' ||
      extension === 'ifc' ||
      extension === 'obj' ||
      extension === 'fbx'
    )
      return true;
    return false;
  }
  return false;
};

export const isPdf = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (file && file.metadata && file?.metadata === 'application/pdf') {
    return true;
  }
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (extension === 'pdf') return true;
    return false;
  }
  return false;
};

export const isImage = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (file && file.metadata && file?.metadata.includes('image')) {
    return true;
  }
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (extension === 'png' || extension === 'jpeg' || extension === 'jpg' || extension === 'gif')
      return true;
    return false;
  }
  return false;
};

export const isMSProject = (file) => {
  const name = file && file.name ? file.name : file && file.fileName ? file.fileName : false;
  if (name) {
    const extension = name.toLowerCase().split('.').pop();
    if (extension === 'mpp' || extension === 'xml') return true;
    return false;
  }
  return false;
};
