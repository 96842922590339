import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormattedMessage, useIntl } from 'react-intl';

import { selectUser } from '../../features/user/userSlice';
import { storageFileUpload } from './storageFileUpload';
import { textMiddleEllipsis } from '../../helpers/textMiddleEllipsis';
import { numberFormat } from '../../helpers/numberFormat';

export default function AddArchivoDetail({
  file,
  index,
  archivos,
  setArchivos,
  currentFolder,
  path,
  storagePath,
  startUpload,
  progressHandler,
  cancelarCarga,
  pausarCarga,
  reanudarCarga,
  idProject,
  idClient,
  disableLogFileChange,
  section,
}) {
  const uploadTaskRef = useRef();
  const userId = useSelector(selectUser).userId;
  const intl = useIntl();
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState();

  useEffect(() => {
    //con este activamos una sola vez la subida de información
    if (startUpload === 'start') {
      uploadFile();
    }
  }, [startUpload]);

  const uploadFile = async () => {
    try {
      await storageFileUpload(
        file,
        path,
        storagePath,
        `${new Date().getTime()}_${file.name}`,
        userId,
        currentFolder,
        (x) => setProgress(x),
        progressHandler,
        index,
        uploadTaskRef,
        idProject,
        idClient,
        disableLogFileChange,
        section
      );
    } catch (err) {
      console.log(err);
    }
  };

  const openFile = (file) => {
    if (file.uri) {
      window.open(file.uri);
    } else {
      window.open(file.preview);
    }
  };

  const deleteFile = (file) => {
    let fileCopy = [...archivos];
    let indexOf = fileCopy.findIndex((x) => x.name === file.name && x.size === file.size);
    if (indexOf > -1) {
      fileCopy.splice(indexOf, 1);
      setArchivos(fileCopy);
    }
  };

  const cancelTask = () => {
    uploadTaskRef.current.cancel();
    setUploadStatus('cancelado');
  };
  const pauseTask = () => {
    uploadTaskRef.current.pause();
    setUploadStatus('pausado');
  };
  const resumeTask = () => {
    uploadTaskRef.current.resume();
    setUploadStatus();
  };

  useEffect(() => {
    if (cancelarCarga === 'cancelar') {
      cancelTask();
    }
  }, [cancelarCarga]);

  useEffect(() => {
    if (pausarCarga === 'pausar') {
      pauseTask();
    }
  }, [pausarCarga]);

  useEffect(() => {
    if (reanudarCarga === 'reanudar') {
      resumeTask();
    }
  }, [reanudarCarga]);

  return (
    <Container start={startUpload === 'start'}>
      <IndexContainer>
        <span>{index + 1}</span>
      </IndexContainer>
      <FileContainer onClick={() => openFile(file)}>
        <span>
          {file.name} ({Math.round((file.size / 1000000) * 100) / 100} MB)
        </span>
      </FileContainer>
      <ActionContainer
        ready={startUpload === 'start' && progress === 100}
        start={startUpload === 'start'}
      >
        {startUpload !== 'start' && (
          <div onClick={() => deleteFile(file)}>
            <span>
              <FormattedMessage id="app.delete" defaultMessage="" />
            </span>
          </div>
        )}
        {startUpload === 'start' && progress < 100 && !uploadStatus && (
          <div onClick={cancelTask}>
            <span>
              <FormattedMessage id="app.cancel" defaultMessage="" />
            </span>
          </div>
        )}
        {startUpload === 'start' && progress < 100 && !uploadStatus && (
          <div onClick={pauseTask}>
            <span>
              <FormattedMessage id="app.pause" defaultMessage="" />
            </span>
          </div>
        )}
        {startUpload === 'start' && progress < 100 && uploadStatus === 'cancelado' && (
          <div>
            <span>
              <FormattedMessage id="app.cancelled" defaultMessage="" />
            </span>
          </div>
        )}
        {startUpload === 'start' && progress < 100 && uploadStatus === 'pausado' && (
          <div onClick={resumeTask}>
            <span>
              <FormattedMessage id="app.resume" defaultMessage="" />
            </span>
          </div>
        )}
        {startUpload === 'start' && progress < 100 && (
          <div>
            <span>{numberFormat(progress, 0)}%</span>
          </div>
        )}
        {startUpload === 'start' && progress === 100 && (
          <div>
            <CheckCircleIcon />
          </div>
        )}
      </ActionContainer>
    </Container>
  );
}

const Container = styled.aside`
  display: grid;
  grid-template-columns: 40px 1fr 200px;
  background-color: whitesmoke;
  margin-bottom: 5px;
  padding: 3px 10px;
  border-radius: 4px;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.start ? 'whitesmoke' : 'darkgrey')};
    > div {
      > span {
        color: ${(props) => (props.start ? 'dimgrey' : 'white')};
      }
    }
  }
`;

const IndexContainer = styled.div`
  display: flex;
  align-items: flex-start;

  > span {
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
  }
`;
const FileContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  > span {
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  > div {
    display: flex;
    opacity: 1;
    background-color: white;
    border-radius: 4px;
    padding: 3px 10px;
    cursor: pointer;
    margin-left: 5px;

    > span {
      font-size: 10px;
      color: grey;
      text-transform: uppercase;
    }

    &:hover {
      background-color: red;
      > span {
        color: white;
      }
    }
  }

  > div:last-child {
    display: flex;
    justify-content: ${(props) => (props.ready ? 'center' : 'flex-end')};
    padding: ${(props) => (props.ready ? '0 10px' : '3px 10px')};
    cursor: ${(props) => (props.start ? 'default' : 'pointer')};
    min-width: 50px;
    > .MuiSvgIcon-root {
      color: limegreen;
      font-size: 18px;
    }
    &:hover {
      background-color: ${(props) => (props.start ? 'white' : 'red')};
      > span {
        color: ${(props) => (props.start ? 'grey' : 'white')};
      }
    }
  }
`;
