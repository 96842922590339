import { Timestamp, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import * as Sentry from '@sentry/react';

export const logAnalytics = async ({ user, path, type, otherProps }) => {
  try {
    await addDoc(collection(db, path), {
      userId: user.userId,
      createdAt: Timestamp.fromDate(new Date()),
      type,
      ...(!!otherProps && otherProps),
    });
  } catch (err) {
    Sentry.captureException(err, (scope) => {
      scope.setTag('email', user.email);
      scope.setTag('id', user.userId);
      scope.setTag('err.code', err.code);
      scope.setTag('type', `analytics:${type}`);
    });
    Sentry.captureMessage(
      `Error Code:${err.code}. Email: ${user.email}. Id: ${user.userId} type: analytics:${type}`,
      'log'
    );
  }
};
