import { convertTZ } from './convertTZ';

export const firestoreDate = (timestamp, showHour, intl, localeString) => {
  if (timestamp) {
    const fireStoreDate = convertTZ(
      new Date((timestamp._seconds || timestamp.seconds) * 1000),
      localeString || 'America/Santiago'
    );
    const day =
      fireStoreDate.getDate() < 10 ? `0${fireStoreDate.getDate()}` : fireStoreDate.getDate();
    const month =
      fireStoreDate.getMonth() + 1 < 10
        ? `0${fireStoreDate.getMonth() + 1}`
        : fireStoreDate.getMonth() + 1;
    const year = fireStoreDate.getFullYear();
    const hours =
      fireStoreDate.getHours() < 10 ? `0${fireStoreDate.getHours()}` : fireStoreDate.getHours();
    const minutes =
      fireStoreDate.getMinutes() < 10
        ? `0${fireStoreDate.getMinutes()}`
        : fireStoreDate.getMinutes();
    const fecha = `${day}-${month}-${year}${showHour ? ` ${hours}:${minutes}hrs` : ''}`;
    const fechaEn = `${month}-${day}-${year}${showHour ? ` ${hours}:${minutes}hrs` : ''}`;
    return intl === 'en' ? fechaEn : fecha;
  } else {
    return `00-00-0000${showHour ? ' 00:00hrs' : ''}`;
  }
};
