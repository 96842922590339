import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';

import animationData from '../../images/loadingScreen/9764-loader.json';

function LoadingScreen({ text, textColor, marginBottom }) {
  return (
    <Container>
      <SubContainer>
        <Lottie
          loop={true}
          animationData={animationData}
          autoPlay={true}
          height={'50px'}
          width={'50px'}
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
        <LoadingText textColor={textColor}>{text ? text : ''}</LoadingText>
      </SubContainer>
      {marginBottom && <div style={{ width: '100%', height: marginBottom }} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  height: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const LoadingText = styled.span`
  margin-top: 24px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => (props.textColor ? props.textColor : null)};
`;

export default LoadingScreen;
