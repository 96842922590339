export const orderBy = (array, orderBy) => {
  if (orderBy) {
    if (
      orderBy === 'fecha' ||
      orderBy === 'date' ||
      orderBy === 'fechaEmision' ||
      orderBy === 'createdAt' ||
      orderBy === 'updatedOn' ||
      orderBy === 'startTime' ||
      orderBy === 'fechaLimite' ||
      orderBy === 'dateStart' ||
      orderBy === 'captureDate' ||
      orderBy === 'revTecDate'
    ) {
      const order = array.sort((a, b) => {
        if (a[orderBy].seconds) {
          if (
            (a[orderBy].seconds || a[orderBy].seconds) > (b[orderBy].seconds || b[orderBy].seconds)
          ) {
            return 1;
          }
          if (
            (a[orderBy].seconds || a[orderBy].seconds) < (b[orderBy].seconds || b[orderBy].seconds)
          ) {
            return -1;
          }
          return 0;
        } else {
          if (
            (a[orderBy]._seconds || a[orderBy]._seconds) >
            (b[orderBy]._seconds || b[orderBy]._seconds)
          ) {
            return 1;
          }
          if (
            (a[orderBy]._seconds || a[orderBy]._seconds) <
            (b[orderBy]._seconds || b[orderBy]._seconds)
          ) {
            return -1;
          }
          return 0;
        }
      });
      return order;
    } else {
      const order = array.sort((a, b) => {
        if (a[orderBy] > b[orderBy]) {
          return 1;
        }
        if (a[orderBy] < b[orderBy]) {
          return -1;
        }
        return 0;
      });
      return order;
    }
  } else {
    return array;
  }
};
