import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/user/userSlice';
import ModalContainer from '../generales/ModalContainer';
import LoadingScreen from '../generales/LoadingScreen';
import { Timestamp, addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { FormInputText } from '../forms/FormInputText';
import { db } from '../../firebase/firebase';

export default function LicitacionesDetailMenuEEditModal({ open, onClose, licitacion, categoria }) {
  const user = useSelector(selectUser);
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const defaultValues = {
    value: categoria.valor ? categoria.valor.value : '',
  };

  const methods = useForm({ defaultValues: defaultValues, mode: 'onChange' });
  const { handleSubmit, reset, control, setValue, formState, watch } = methods;

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      if (categoria.valor) {
        await updateDoc(doc(db, `licitaciones_valores/${categoria.valor.idRegistro}`), {
          value: parseFloat(data.value),
          updatedOn: Timestamp.fromDate(new Date()),
        });
      } else {
        await addDoc(collection(db, 'licitaciones_valores'), {
          value: parseFloat(data.value),
          idCategoria: categoria.idCategoria,
          idLicitacion: licitacion.idLicitacion,
          idProject: licitacion.idProject,
          userId: user.userId,
          createdAt: Timestamp.fromDate(new Date()),
        });
      }
      setLoading(false);
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  const deleteHandler = async () => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, `licitaciones_valores/${categoria.valor.idRegistro}`));
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  const isValid = () => {
    if (!formState.isValid || (!categoria.negative && parseFloat(watch('value')) < 0) || loading)
      return false;
    return true;
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({
        id: 'app.edit',
        defaultMessage: 'Edit',
      })} ${intl.formatMessage({ id: 'app.value', defaultMessage: 'Value' })}`}
      maxWidth={'400px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.save', defaultMessage: 'Save' }),
          isValid: isValid(),
          onClick: isValid() ? handleSubmit(submitHandler) : null,
        },
      ]}
      deleteHandler={categoria.valor ? () => deleteHandler() : null}
    >
      {loading ? (
        <LoadingScreen />
      ) : (
        <ContentContainer>
          <InputContainer>
            <span>{categoria.label}</span>
            <span>{categoria.negative ? '(Permite valores negativos)' : ''}</span>
            <FormInputText
              name={'value'}
              control={control}
              label={'Valor'}
              type={'Number'}
              required={categoria.required}
              marginBottom={'0px'}
            />
            {!categoria.negative && parseFloat(watch('value')) < 0 && (
              <span>No puede ser negativo</span>
            )}
          </InputContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;

  > span:nth-child(1) {
    font-size: 14px;
    font-weight: 300;
  }
  > span:nth-child(2) {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 20px;
  }
  > span:nth-child(4) {
    font-size: 12px;
    color: red;
    margin: 5px 0 0 15px;
  }
`;
