import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import reloadImage from '../../images/generales/undraw_version_control_re_mg66.svg';

export default function ReloadAlert({ open, onClose }) {
  const onClick = () => {
    window.localStorage.removeItem('version-update-needed'); // remove the storage object
    window.location.reload(); // refresh the browser
  };

  const closeHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      disableEscapeKeyDown={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open} style={{ outline: 0 }}>
        <Container>
          <Title>Nueva versión de nupav disponible</Title>
          <ImgContainer>
            <img src={reloadImage} alt="" />
          </ImgContainer>
          <Button onClick={onClick}>
            <span>REFRESCAR</span>
          </Button>
        </Container>
      </Fade>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.theme.name === 'light' ? 'white' : 'black')};
  border-radius: 10px;
  padding: 20px 30px;
  overflow-y: scroll;
  max-height: 70%;
  max-width: 90%;
`;

const Title = styled.span`
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 30px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.primaryColor};
  margin-top: 50px;
  cursor: pointer;

  > span {
    font-size: 12px;
    color: white;
  }

  &:hover {
    background-color: ${(props) => props.theme.cincoColor};
  }
`;

const ImgContainer = styled.div`
  display: flex;
  width: 250px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
