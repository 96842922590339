import React from 'react';
import styled from 'styled-components';

import noDataImage from '../../images/generales/undraw_no_data_re_kwbl-5.svg';

export default function PageLoadingError({ text, backButton }) {
  return (
    <ContentContainer>
      <img src={noDataImage} alt="" />
      <span>{text ? text : 'Error al cargar la data'}</span>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1024px;

  > img {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
  }

  > span {
    margin-top: 24px;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
  }
`;
