import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiIcon from './MuiIcon';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    fontFamily: '"Inter", sans-serif',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontFamily: '"Inter", sans-serif',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const StyledButton = styled(Button)({
  outline: 'none',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  fontWeight: 300,
  padding: '2px 7px',
  marginLeft: '10px',
  lineHeight: 1.5,
  backgroundColor: '#7B83B9',
  borderColor: '#7B83B9',
  fontFamily: '"Inter", sans-serif',
  '&:hover': {
    backgroundColor: '#5A64A7',
    borderColor: '#5A64A7',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#5A64A7',
    borderColor: '#5A64A7',
    boxShadow: 'none',
    outline: 'none',
  },
  '&:focus': {
    // boxShadow: '0 0 0 0.1rem rgba(255,255,255,.5)',
    backgroundColor: '#5A64A7',
    outline: 'none',
  },
});

export default function PageButtonSelectHeader({ name = 'Button', buttons = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {name}
      </StyledButton>
      {buttons.length > 0 && (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {buttons.map((x, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                x.onClick();
                handleClose();
              }}
              disableRipple
            >
              <MuiIcon name={x.iconName} />
              {x.name}
            </MenuItem>
          ))}
          {/* <Divider sx={{ my: 0.5 }} /> */}
        </StyledMenu>
      )}
    </>
  );
}
