import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';
import { loadCldr } from '@syncfusion/ej2-base';

import App from './App';
import { store } from './app/store';

import './index.css';

Sentry.init({
  dsn: 'https://75bee4f58a09fb7081c163db826a6431@o4505092154261504.ingest.us.sentry.io/4507200970424320',
  environment: process.env.NODE_ENV || 'unknown',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// parámetros para syncfusion
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
loadCldr(
  require('cldr-data/main/es/ca-gregorian.json'),
  require('cldr-data/main/es/timeZoneNames.json'),
  require('cldr-data/main/es/numbers.json'),
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/supplemental/weekData.json'),
  require('cldr-data/main/es/currencies.json')
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
