export const differenceDays = (majorDay, minorDay) => {
  return Math.round((majorDay.getTime() - minorDay.getTime()) / (1000 * 60 * 60 * 24));
};

export const differenceDaysHoursMinutesSeconds = (fechaFin, fechaInicio) => {
  // Calcula la diferencia en milisegundos
  const diferencia = fechaFin - fechaInicio;

  // Constantes de tiempo
  const MILISEGUNDOS_POR_SEGUNDO = 1000;
  const SEGUNDOS_POR_MINUTO = 60;
  const MINUTOS_POR_HORA = 60;
  const HORAS_POR_DIA = 24;
  const MILISEGUNDOS_POR_MINUTO = MILISEGUNDOS_POR_SEGUNDO * SEGUNDOS_POR_MINUTO;
  const MILISEGUNDOS_POR_HORA = MILISEGUNDOS_POR_MINUTO * MINUTOS_POR_HORA;
  const MILISEGUNDOS_POR_DIA = MILISEGUNDOS_POR_HORA * HORAS_POR_DIA;

  // Calcular días, horas, minutos y segundos
  const dias = Math.floor(diferencia / MILISEGUNDOS_POR_DIA);
  const horasRestantes = Math.floor((diferencia % MILISEGUNDOS_POR_DIA) / MILISEGUNDOS_POR_HORA);
  const minutosRestantes = Math.floor(
    (diferencia % MILISEGUNDOS_POR_HORA) / MILISEGUNDOS_POR_MINUTO
  );
  const segundosRestantes = Math.floor(
    (diferencia % MILISEGUNDOS_POR_MINUTO) / MILISEGUNDOS_POR_SEGUNDO
  );

  return { dias, horasRestantes, minutosRestantes, segundosRestantes };
};

export const actualizarCuentaRegresiva = (fechaFin) => {
  const fechaActual = new Date();
  const diferencia = fechaFin - fechaActual;

  if (diferencia >= 0) {
    return differenceDaysHoursMinutesSeconds(fechaFin, fechaActual);
  } else {
    clearInterval(intervalo);
  }
};

// Actualizar la cuenta regresiva cada segundo
const intervalo = setInterval(actualizarCuentaRegresiva, 1000);
