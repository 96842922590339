import { isAutocadDoc, isGoogleDoc, isMSProject, isOfficeDoc } from './mimeTypes';

const correctString = (name, isImage) => {
  if (name) {
    let nameArray = name.split('_');
    nameArray.splice(0, 1);
    if (isImage) {
      nameArray.splice(nameArray.length - 1, 1);
    }
    return nameArray.join('_');
  }
  return '';
};

export const nameHandler = (x) => {
  const name = x?.displayName
    ? x.displayName
    : x?.metadata?.includes('image') && x.uriLargeName
    ? correctString(x.uriLargeName, true)
    : x?.metadata?.includes('image') && x.name
    ? correctString(x.name, false)
    : correctString(x.name, false);
  return name;
};

export const openFileHandler = (file, imageFiles, setImageModal, history, location) => {
  const isClientRoute = location.pathname.split('/')[1] === 'clients';
  const name = nameHandler(file);

  const isGoogleDocument = isGoogleDoc(file);
  const isOfficeDocument = isOfficeDoc(file);
  const isAutocadDocument = isAutocadDoc(file);
  const isMSProjectDocument = isMSProject(file);

  const url = file?.metadata?.includes('image') && file.uriLarge ? file.uriLarge : file.uri;
  if (file?.metadata?.includes('image')) {
    //creamos el array de las imagenes y buscamos el index
    let startIndex = 0;
    let images = [];
    let n = 0;
    imageFiles.forEach((x) => {
      if (x.idFile === file.idFile) {
        startIndex = n;
      }
      images.push({
        src: x.uriLarge ? x.uriLarge : x.uri,
        title: nameHandler(x),
      });
      n++;
    });
    // console.log({ images, startIndex });
    setImageModal({
      open: true,
      images,
      startIndex,
    });
  } else if (file?.metadata === 'application/pdf') {
    history.push({
      pathname: isClientRoute
        ? `/clients/${location.pathname.split('/')[2]}/pdfviewer/${file.idFile}`
        : `/${location.pathname.split('/')[1]}/pdfviewer/${file.idFile}`,
      state: { url, fileName: name, idFile: file.idFile },
    });
  } else if (isOfficeDocument || isGoogleDocument) {
    history.push({
      pathname: isClientRoute
        ? `/clients/${location.pathname.split('/')[2]}/docviewer/${file.idFile}`
        : `/${location.pathname.split('/')[1]}/docviewer/${file.idFile}`,
      state: { url, fileName: name, idFile: file.idFile, isGoogleDocument },
    });
  } else if (isAutocadDocument) {
    history.push({
      pathname: isClientRoute
        ? `/clients/${location.pathname.split('/')[2]}/cadviewer/${file.idFile}`
        : `/${location.pathname.split('/')[1]}/cadviewer/${file.idFile}`,
      state: { file },
    });
  } else if (isMSProjectDocument) {
    history.push({
      pathname: isClientRoute
        ? `/clients/${location.pathname.split('/')[2]}/ganttviewer/${file.idFile}`
        : `/${location.pathname.split('/')[1]}/ganttviewer/${file.idFile}`,
      state: { file },
    });
  } else {
    history.push({
      pathname: isClientRoute
        ? `/clients/${location.pathname.split('/')[2]}/noview/${file.idFile}`
        : `/${location.pathname.split('/')[1]}/noview/${file.idFile}`,
      state: { url, name },
    });
  }
};

export const openFileHandlerModal = (
  file,
  imageFiles,
  setImageModal,
  setPdfModal,
  setCadModal,
  setDocModal,
  setNoViewModal
) => {
  // console.log(file);
  const name = nameHandler(file);

  const isGoogleDocument = isGoogleDoc(file);
  const isOfficeDocument = isOfficeDoc(file);
  const isAutocadDocument = isAutocadDoc(file);

  const url = file?.metadata?.includes('image') && file.uriLarge ? file.uriLarge : file.uri;
  if (file?.metadata?.includes('image')) {
    // console.log('es imagen');
    //creamos el array de las imagenes y buscamos el index
    let startIndex = 0;
    let images = [];
    let n = 0;
    imageFiles.forEach((x) => {
      if (x.idFile === file.idFile) {
        startIndex = n;
      }
      images.push({
        src: x.uriLarge ? x.uriLarge : x.uri,
        title: nameHandler(x),
      });
      n++;
    });
    // console.log({ images, startIndex });
    setImageModal({
      open: true,
      images,
      startIndex,
    });
  } else if (file?.metadata === 'application/pdf') {
    setPdfModal({
      open: true,
      url,
      fileName: name,
      idFile: file.idFile,
    });
  } else if (isOfficeDocument || isGoogleDocument) {
    setDocModal({
      open: true,
      url,
      fileName: name,
      idFile: file.idFile,
      isGoogleDocument,
    });
  } else if (isAutocadDocument) {
    setCadModal({
      open: true,
      file,
    });
  } else {
    setNoViewModal({
      open: true,
      url,
      fileName: name,
      idFile: file.idFile,
    });
  }
};
