import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import ModalContainer from '../generales/ModalContainer';
import { nameHandler, openFileHandlerModal } from '../../helpers/openFileHandler';
import PdfViewerModal from '../pdfViewer/PdfViewerModal';
import ForgeViewerModal from '../forgeViewer/ForgeViewerModal';
import DocViewerModal from '../docViewer/DocViewerModal';
import NoViewerModal from '../noViewer/NoViewerModal';

export default function LicitacionesDetailMenuCFileModal({ open, onClose, files }) {
  const intl = useIntl();

  const [imageModal, setImageModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [cadModal, setCadModal] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [noViewModal, setNoViewModal] = useState(false);

  const filesQ = files.filter((x) => x.idParticipant);
  const filesA = files.filter((x) => !x.idParticipant);

  const openHandler = (file) => {
    openFileHandlerModal(
      file,
      [file],
      (x) => setImageModal(x),
      (x) => setPdfModal(x),
      (x) => setCadModal(x),
      (x) => setDocModal(x),
      (x) => setNoViewModal(x)
    );
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={intl.formatMessage({
        id: 'app.attachments',
        defaultMessage: '',
      })}
      maxWidth={'600px'}
    >
      <ContentContainer>
        <InputContainer>
          <Title>
            <FormattedMessage id={'app.files'} />
          </Title>
          {[...filesA, ...filesQ].length > 0 ? (
            <FileContainer>
              {[...filesA, ...filesQ].map((x) => (
                <ItemContainer>
                  <div onClick={() => openHandler(x)}>
                    <span>{nameHandler(x)}</span>
                  </div>
                </ItemContainer>
              ))}
            </FileContainer>
          ) : (
            <NoFile>Sin Archivos</NoFile>
          )}
        </InputContainer>
        {/* <InputContainer>
          <Title>Archivos de Pregunta</Title>
          {filesQ.length > 0 ? (
            <FileContainer>
              {filesQ.map((x) => (
                <ItemContainer>
                  <div onClick={() => openHandler(x)}>
                    <span>{nameHandler(x)}</span>
                  </div>
                </ItemContainer>
              ))}
            </FileContainer>
          ) : (
            <NoFile>Sin Archivos</NoFile>
          )}
        </InputContainer> */}
        {imageModal.open && (
          <Lightbox
            open={imageModal.open}
            slides={imageModal.images}
            close={() => setImageModal(false)}
            index={imageModal.startIndex}
          />
        )}
        {pdfModal.open && (
          <PdfViewerModal
            open={pdfModal.open}
            onClose={() => setPdfModal(false)}
            url={pdfModal.url}
            fileName={pdfModal.fileName}
            idFile={`index_${pdfModal.idFile}`}
          />
        )}
        {cadModal.open && (
          <ForgeViewerModal
            open={cadModal.open}
            onClose={() => setCadModal(false)}
            file={cadModal.file}
          />
        )}
        {docModal.open && (
          <DocViewerModal
            open={docModal.open}
            onClose={() => setDocModal(false)}
            url={docModal.url}
            fileName={docModal.fileName}
            idFile={docModal.idFile}
          />
        )}
        {noViewModal.open && (
          <NoViewerModal
            open={noViewModal.open}
            onClose={() => setNoViewModal(false)}
            url={noViewModal.url}
            fileName={noViewModal.fileName}
            idFile={noViewModal.idFile}
          />
        )}
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 25px;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 5px 5px;
  margin-bottom: 5px;

  > div {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    > span {
      color: black;
      font-size: 13px;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > .MuiSvgIcon-root {
    font-size: 20px;
    cursor: pointer;
    color: gray;
  }

  /* &:hover {
    background-color: ${(props) => props.theme.orangeColor};
    > div {
      > span {
        color: white;
      }
    }
    > .MuiSvgIcon-root {
      color: white;
    }
  } */
`;

const NoFile = styled.span`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
`;
