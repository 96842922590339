import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import LoadingScreen from '../generales/LoadingScreen';
import ModalContainer from '../generales/ModalContainer';

export default function CancelModal({ open, onClose, cancelAllTask }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const cancelUploadHandler = () => {
    setLoading(true);
    setTimeout(() => {
      cancelAllTask();
      onClose();
    }, 800);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({ id: 'app.cancel', defaultMessage: '' })}`}
      maxWidth={'400px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.cancel', defaultMessage: '' }),
          isValid: !loading,
          onClick: loading ? null : cancelUploadHandler,
        },
      ]}
    >
      <Container>
        {!loading && (
          <SubTitle>
            <FormattedMessage id="app.addfile.mesN" defaultMessage="" />
          </SubTitle>
        )}
        {loading && (
          <LoadingScreen
            text={`${intl.formatMessage({ id: 'app.loading', defaultMessage: '' })}...`}
          />
        )}
      </Container>
    </ModalContainer>
  );
}

const Container = styled.div`
  display: flex;
  padding: 30px;
`;
const SubTitle = styled.span`
  text-align: justify;
  font-weight: 300;
  font-size: 14px;
`;
