import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Timestamp } from 'firebase/firestore';

import ModalContainer from '../generales/ModalContainer';
import FormDateTime from '../forms/FormDateTime';
import AddArchivo from './AddArchivo';

export default function AddFileModal({
  open,
  onClose,
  maxArchivos, // int: numero total de archivos permitidos
  currentFolder, // string: solo aplicable si se esta trabjando con sistema de archivos ej: idFolder
  path, // string: para saber donde guardar ej: obras/idObra/section
  storagePath,
  acceptedFileType, // string: mimetype separados por coma ej: image/jpeg,image/png,image/gif,image/jpg,application/pdf
  idProject,
  idClient,
  disableLogFileChange,
  section,
  addDate, //por si queremos agregar fecha a los archivos / imagenes que se estan agregando
}) {
  const intl = useIntl();
  const [date, setDate] = useState(null);
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={intl.formatMessage({ id: 'app.addfile.mesK', defaultMessage: '' })}
      maxHeight={'90%'}
      maxWidth={'80%'}
    >
      <ContentContainer>
        {addDate && (
          <DateContainer>
            <span>
              <FormattedMessage id="app.fotos.textF" defaultMessage="" />
            </span>
            <FormDateTime
              value={date}
              setValue={(x) => setDate(x)}
              label={'Fecha'}
              disabled={false}
            />
          </DateContainer>
        )}
        <AddArchivo
          onClose={onClose}
          path={path}
          storagePath={storagePath}
          maxArchivos={maxArchivos ? maxArchivos : 1}
          currentFolder={currentFolder ? currentFolder : null}
          acceptedFileType={acceptedFileType ? acceptedFileType : false}
          idProject={idProject}
          idClient={idClient}
          disableLogFileChange={disableLogFileChange}
          section={
            addDate && date
              ? section
                ? { ...section, date: Timestamp.fromDate(date) }
                : { date: Timestamp.fromDate(date) }
              : section
          }
        />
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  > span {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  > div {
    max-width: 300px;
  }
`;
