import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../firebase/firebase';
import { Timestamp, addDoc, collection } from 'firebase/firestore';

export const storageFileUpload = async (
  file,
  path,
  storagePath,
  idFile,
  userId,
  currentFolder,
  setProgress,
  progressHandler,
  index,
  uploadTaskRef,
  idProject,
  idClient,
  disableLogFileChange,
  section
) => {
  const storageRef = ref(storage, `${storagePath}/${idFile}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTaskRef.current = uploadTask;
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(prog);
      progressHandler(index, prog);
      // console.log('Upload is ' + prog + '% done');
    },
    (err) => {
      console.log(err);
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(storageRef);
        const fileData = {
          createdAt: Timestamp.fromDate(new Date()),
          userId,
          uri: downloadURL,
          size: file.size,
          path: uploadTaskRef.current.snapshot.ref.fullPath,
          name: uploadTaskRef.current.snapshot.ref.name,
          metadata: file.type,
          idFolder: currentFolder ? currentFolder.idFolder : null,
          deleted: false,
          idProject: idProject ? idProject : null,
          idClient: idClient ? idClient : null,
          ...(!!section && section),
        };

        const doc = await addDoc(collection(db, path), { ...fileData });
        if (!disableLogFileChange) {
          await addDoc(collection(db, `${path}/${doc.id}/log_file_changes`), { ...fileData });
        }

        return {
          idFile: doc.id,
          ...fileData,
        };
      } catch (err) {
        console.log({ error: err, file: 'storageFileUpload' });
      }
    }
  );
};
