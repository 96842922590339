export const monedas = [
  { label: 'Unidad de Fomento (UF)', id: 'moneda1', val: 'UF', cs: 2},
  { label: 'Pesos Chilenos (Pesos)', id: 'moneda2', val: 'Pesos', cs: 0},
  { label: 'Dólares (US)', id: 'moneda3', val: 'Dólares', cs: 2},
];

export const monedasEn = [
  { label: 'Unidad de Fomento (UF)', id: 'moneda1', val: 'UF', cs: 2},
  { label: 'Chilean Pesos (Pesos)', id: 'moneda2', val: 'Pesos', cs: 0},
  { label: 'Dollars  (US)', id: 'moneda3', val: 'Dollars', cs: 2},
];