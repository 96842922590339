import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { selectUser } from '../../features/user/userSlice';
import { offerDocTypes } from '../../data/offerDocTypes';
import ModalContainer from '../generales/ModalContainer';
import { nameHandler, openFileHandlerModal } from '../../helpers/openFileHandler';
import PdfViewerModal from '../pdfViewer/PdfViewerModal';
import ForgeViewerModal from '../forgeViewer/ForgeViewerModal';
import DocViewerModal from '../docViewer/DocViewerModal';
import NoViewerModal from '../noViewer/NoViewerModal';
import AddFileModal from '../addFiles/AddFileModal';
import LicitacionesDetailMenuDFileDeleteModal from './Licitaciones_detail_menuD_fileDeleteModal';
import { Tooltip } from '@mui/material';

export default function LicitacionesDetailMenuDFileModal({
  open,
  onClose,
  licitacion,
  categoria,
  files,
  isStageActive,
  periodoEntregaOfertas,
}) {
  const user = useSelector(selectUser);
  const intl = useIntl();

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [cadModal, setCadModal] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [noViewModal, setNoViewModal] = useState(false);

  const file = files.find((x) => x.idCategoria === categoria.idCategoria);

  const openHandler = (file) => {
    openFileHandlerModal(
      file,
      [],
      (x) => setImageModal(x),
      (x) => setPdfModal(x),
      (x) => setCadModal(x),
      (x) => setDocModal(x),
      (x) => setNoViewModal(x)
    );
  };

  const types = offerDocTypes.find((x) => x.id === categoria.type);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({
        id: 'app.add',
        defaultMessage: 'Add',
      })} ${intl.formatMessage({ id: 'app.document', defaultMessage: 'Document' })}`}
      maxWidth={'600px'}
    >
      <ContentContainer>
        <InputContainer>
          <Title>{intl.formatMessage({ id: 'app.document' })}</Title>
          <Description>{categoria.label}</Description>
          <Spacer height={'10px'} />
          <Title>{intl.formatMessage({ id: 'app.description' })}</Title>
          {(categoria.description || '').length > 0 ? (
            <Description>{categoria.description}</Description>
          ) : (
            <NoDescription>
              <FormattedMessage id="app.licitaciones.detailMenuDc" />
            </NoDescription>
          )}
          <Spacer height={'10px'} />
          <Title>
            <FormattedMessage id="app.licitaciones.detailMenuDd" />
          </Title>
          <Description>
            {types ? types.label : intl.formatMessage({ id: 'app.licitaciones.detailMenuDg' })}
          </Description>
        </InputContainer>
        <InputContainer>
          <Title>
            <FormattedMessage id="app.licitaciones.detailMenuDe" />
          </Title>
          <Tooltip title={!file ? '' : intl.formatMessage({ id: 'app.licitaciones.detailMenuDf' })}>
            <ButtonContainer allowed={!file}>
              {isStageActive && periodoEntregaOfertas && (
                <AddCircleIcon onClick={!file ? () => setAddModal({ open: true }) : null} />
              )}
            </ButtonContainer>
          </Tooltip>
          {file && (
            <FileContainer allowed={false}>
              <ItemContainer>
                <div onClick={() => openHandler(file)}>
                  <span>{nameHandler(file)}</span>
                </div>
                {isStageActive && periodoEntregaOfertas && (
                  <DeleteIcon onClick={() => setDeleteModal({ open: true, file: file })} />
                )}
              </ItemContainer>
            </FileContainer>
          )}
        </InputContainer>
        {imageModal.open && (
          <Lightbox
            open={imageModal.open}
            slides={imageModal.images}
            close={() => setImageModal(false)}
            index={imageModal.startIndex}
          />
        )}
        {pdfModal.open && (
          <PdfViewerModal
            open={pdfModal.open}
            onClose={() => setPdfModal(false)}
            url={pdfModal.url}
            fileName={pdfModal.fileName}
            idFile={`index_${pdfModal.idFile}`}
          />
        )}
        {cadModal.open && (
          <ForgeViewerModal
            open={cadModal.open}
            onClose={() => setCadModal(false)}
            file={cadModal.file}
          />
        )}
        {docModal.open && (
          <DocViewerModal
            open={docModal.open}
            onClose={() => setDocModal(false)}
            url={docModal.url}
            fileName={docModal.fileName}
            idFile={docModal.idFile}
          />
        )}
        {noViewModal.open && (
          <NoViewerModal
            open={noViewModal.open}
            onClose={() => setNoViewModal(false)}
            url={noViewModal.url}
            fileName={noViewModal.fileName}
            idFile={noViewModal.idFile}
          />
        )}
        {addModal.open && (
          <AddFileModal
            open={addModal.open}
            onClose={() => setAddModal(false)}
            maxArchivos={1}
            path={'licitaciones_files'}
            storagePath={`licitaciones_files/${user.userId}`}
            section={{
              idCategoria: categoria.idCategoria,
              idLicitacion: licitacion.idLicitacion,
              idProject: licitacion.idProject,
              userId: user.userId,
            }}
            acceptedFileType={(types || {}).types || null}
            disableLogFileChange={true}
          />
        )}
        {deleteModal.open && (
          <LicitacionesDetailMenuDFileDeleteModal
            open={deleteModal.open}
            onClose={() => setDeleteModal(false)}
            file={deleteModal.file}
          />
        )}
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const NoDescription = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  font-style: italic;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  > .MuiSvgIcon-root {
    font-size: 20px;
    color: ${(props) => (props.allowed ? 'grey' : 'lightgrey')};
    cursor: ${(props) => (props.allowed ? 'pointer' : 'not-allowed')};

    &:hover {
      transition: 0.1s all ease-in-out;
      color: ${(props) => (props.allowed ? props.theme.orangeColor : 'lightgrey')};
      scale: 1.1;
    }
  }
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.allowed ? '0' : '20px 0 0px 0')};
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  padding: 5px 5px;
  margin-bottom: 5px;

  > div {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    > span {
      color: black;
      font-size: 13px;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  > .MuiSvgIcon-root {
    font-size: 20px;
    cursor: pointer;
    color: gray;
  }

  &:hover {
    background-color: ${(props) => props.theme.orangeColor};
    > div {
      > span {
        color: white;
      }
    }
    > .MuiSvgIcon-root {
      color: white;
    }
  }
`;
