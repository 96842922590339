import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import ModalContainer from '../generales/ModalContainer';
import { nameHandler, openFileHandlerModal } from '../../helpers/openFileHandler';
import PdfViewerModal from '../pdfViewer/PdfViewerModal';
import ForgeViewerModal from '../forgeViewer/ForgeViewerModal';
import DocViewerModal from '../docViewer/DocViewerModal';
import NoViewerModal from '../noViewer/NoViewerModal';

export default function LicitacionesDetailMenuDInfoModal({ open, onClose, categoria }) {
  const intl = useIntl();

  const [imageModal, setImageModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [cadModal, setCadModal] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const [noViewModal, setNoViewModal] = useState(false);

  const openHandler = (file) => {
    openFileHandlerModal(
      file,
      [],
      (x) => setImageModal(x),
      (x) => setPdfModal(x),
      (x) => setCadModal(x),
      (x) => setDocModal(x),
      (x) => setNoViewModal(x)
    );
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={intl.formatMessage({
        id: 'app.detail',
        defaultMessage: 'Detail',
      })}
      maxWidth={'600px'}
    >
      <ContentContainer>
        <InputContainer>
          <Title>{intl.formatMessage({ id: 'app.document' })}</Title>
          <Description>{categoria.label}</Description>
          <Spacer height={'10px'} />
          <Title>{intl.formatMessage({ id: 'app.description' })}</Title>
          {(categoria.description || '').length > 0 ? (
            <Description>{categoria.description}</Description>
          ) : (
            <NoDescription>Sin descripción</NoDescription>
          )}
          <Spacer height={'10px'} />
          <Title>{intl.formatMessage({ id: 'app.required' })}</Title>
          <Description>{categoria.required ? 'Si' : 'No'}</Description>
        </InputContainer>
        <InputContainer>
          <Title>Plantillas o archivos de ejemplo</Title>
          {categoria.docsFiles.length === 0 && <NoDescription>No hay archivos</NoDescription>}
          {categoria.docsFiles.length > 0 && <Spacer height={'10px'} />}
          {categoria.docsFiles.map((x) => (
            <FileContainer key={x.idFile} onClick={() => openHandler(x)}>
              <FiberManualRecordIcon />
              <span>{nameHandler(x)}</span>
            </FileContainer>
          ))}
        </InputContainer>
        {imageModal.open && (
          <Lightbox
            open={imageModal.open}
            slides={imageModal.images}
            close={() => setImageModal(false)}
            index={imageModal.startIndex}
          />
        )}
        {pdfModal.open && (
          <PdfViewerModal
            open={pdfModal.open}
            onClose={() => setPdfModal(false)}
            url={pdfModal.url}
            fileName={pdfModal.fileName}
            idFile={`index_${pdfModal.idFile}`}
          />
        )}
        {cadModal.open && (
          <ForgeViewerModal
            open={cadModal.open}
            onClose={() => setCadModal(false)}
            file={cadModal.file}
          />
        )}
        {docModal.open && (
          <DocViewerModal
            open={docModal.open}
            onClose={() => setDocModal(false)}
            url={docModal.url}
            fileName={docModal.fileName}
            idFile={docModal.idFile}
          />
        )}
        {noViewModal.open && (
          <NoViewerModal
            open={noViewModal.open}
            onClose={() => setNoViewModal(false)}
            url={noViewModal.url}
            fileName={noViewModal.fileName}
            idFile={noViewModal.idFile}
          />
        )}
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const Title = styled.span`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const NoDescription = styled.span`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  font-style: italic;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

const FileContainer = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 2px 10px;
  margin-bottom: 5px;
  align-items: center;
  cursor: pointer;

  > .MuiSvgIcon-root {
    font-size: 10px;
    margin-right: 10px;
    color: gray;
  }
  > span {
    font-size: 13px;
    font-weight: 300;
  }

  &:hover {
    background-color: white;
  }
`;
