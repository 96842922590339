import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { selectTheme } from '../../features/theme/themeSlice';

export const FormInputTextMultine = ({ name, control, label, disabled, rows, required }) => {
  const theme = useSelector(selectTheme);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: required === true ? true : required === false ? false : true,
          message: 'Este campo es requerido',
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error }, formState }) => (
        <>
          <StyledTextField
            selecttheme={theme}
            helperText={error ? error.message : null}
            size={'small'}
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth
            label={label}
            variant={'outlined'}
            disabled={disabled || false}
            multiline
            rows={rows || 8}
          />
        </>
      )}
    />
  );
};

const StyledTextField = styled(TextField)(({ selecttheme }) => ({
  '& label': {
    color: selecttheme === 'light' ? 'grey' : 'white',
    fontSize: 14,
    fontWeight: 300,
  },
  '& input': {
    color: selecttheme === 'light' ? 'grey' : 'white',
    fontSize: 14,
    fontWeight: 300,
  },
  '& fieldset': {
    borderColor: '#ced4da',
    backgroundColor: selecttheme === 'light' ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
  },
  '& label.Mui-focused': {
    color: '#80bdff',
  },
  '& div': {
    color: selecttheme === 'light' ? 'grey' : 'white',
    fontSize: 14,
    fontWeight: 300,
  },
  '& input:valid + fieldset': {
    borderColor: '#ced4da !important',
    borderWidth: 1,
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2,
  },
  '& input:hover fieldset': {
    borderColor: 'yellow',
  },
  '& input:valid:focus + fieldset': {
    borderColor: '#80bdff',
    padding: '4px !important', // override inline-style
  },
  '&:hover fieldset': {
    borderColor: '#80bdff !important',
  },
}));
