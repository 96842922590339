import React from 'react';

import ModalContainer from '../generales/ModalContainer';
import EmptyScreen from '../generales/EmptyScreen';
import FullWindow from './FullWindow';

export default function PdfViewerModal({ open, onClose, url, fileName, idFile }) {
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={fileName}
      maxHeight={'100vh - 100px'}
      maxWidth={'100%'}
      downloadUrl={url}
    >
      {!(url && fileName && idFile) ? (
        <EmptyScreen text={'No se ha podido mostrar el archivo'} />
      ) : (
        <div style={{ width: '100%', height: 'calc(100vh - 100px)' }}>
          <FullWindow url={url} fileName={fileName} idFile={idFile} />
        </div>
      )}
    </ModalContainer>
  );
}
