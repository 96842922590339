import React, { useState } from 'react';
import styled from 'styled-components';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { FormattedMessage, useIntl } from 'react-intl';

import useWindowDimensions from '../../helpers/windowDimensions';
import { orderBy } from '../../helpers/orderBy';
import PageContent from '../generales/PageContent';
import LicitacionesDetailMenuBFolder from './Licitaciones_detail_menuB_folder';
import LicitacionesDetailMenuBFile from './Licitaciones_detail_menuB_file';
import LicitacionesDetailMenuBBreadCrumbs from './Licitaciones_detail_menuB_breadCrumbs';
import PageButtonHeader from '../generales/PageButtonHeader';
import LicitacionesDetailMenuBDownloadModal from './Licitaciones_detail_menuB_downloadModal';

export default function LicitacionesDetailMenuBFiles({ foldersData, filesData }) {
  const { width } = useWindowDimensions();
  const intl = useIntl();

  const [currentFolder, setCurrentFolder] = useState();
  const [order, setOrder] = useState({ type: 'name', desc: false });
  const [downloadModal, setDownloadModal] = useState(false);
  const [selected, setSelected] = useState({
    files: [],
    folders: [],
  });

  const folders = foldersData.filter((x) =>
    currentFolder?.idFolder ? x.parentId === currentFolder.idFolder : !x.parentId
  );
  const files = filesData.filter((x) =>
    currentFolder?.idFolder ? x.idFolder === currentFolder.idFolder : !x.idFolder
  );

  const orderHandler = (array) => {
    if (!order) {
      return orderBy(array, 'name');
    } else if (order.desc) {
      return orderBy(array, order.type).reverse();
    } else {
      return orderBy(array, order.type);
    }
  };

  const allSelectedHandler = () => {
    if (selected.folders.length === 0 && selected.files.length === 0) {
      return { none: true };
    } else if (
      (folders.length > 0 &&
        folders.length === selected.folders.length &&
        files.length > 0 &&
        files.length === selected.files.length) ||
      (folders.length === 0 && files.length > 0 && files.length === selected.files.length) ||
      (folders.length > 0 && folders.length === selected.folders.length && files.length === 0)
    ) {
      return { all: true };
    } else {
      return { parcial: true };
    }
  };

  const selection = allSelectedHandler();

  const orderFn = (type) => {
    let prevType = order.type;
    let prevDesc = order.desc;
    if (prevType === type) {
      setOrder({ type, desc: !prevDesc });
    } else {
      setOrder({ type, desc: false });
    }
  };

  return (
    <PageContent boxShadow={'none'} margin={'0'} width={'100%'}>
      <Container>
        <PanelGroup
          autoSaveId="conditional"
          direction={'horizontal'}
          disablePointerEventsDuringResize={true}
        >
          <Panel id={'right'} defaultSize={50} order={2} minSize={10}>
            <ContentContainerRight>
              <ContentContainer>
                <HeaderContainer allSelected={selection} type={order.type} desc={order.desc}>
                  <LicitacionesDetailMenuBBreadCrumbs
                    currentFolder={currentFolder}
                    setCurrentFolder={(x) => setCurrentFolder(x)}
                    setSelected={() => setSelected({ files: [], folders: [] })}
                    folders={foldersData}
                  />
                  <div>
                    <div>
                      {selection.none && (
                        <CheckBoxOutlineBlankIcon
                          style={{ color: 'lightgrey' }}
                          onClick={() =>
                            setSelected({
                              files: files.map((x) => {
                                return x.idFile;
                              }),
                              folders: [...folders],
                            })
                          }
                        />
                      )}
                      {selection.all && (
                        <CheckBoxIcon onClick={() => setSelected({ files: [], folders: [] })} />
                      )}
                      {selection.parcial && (
                        <IndeterminateCheckBoxIcon
                          onClick={() =>
                            setSelected({
                              files: files.map((x) => {
                                return x.idFile;
                              }),
                              folders: [...folders],
                            })
                          }
                        />
                      )}
                    </div>
                    <div onClick={() => orderFn('name')}>
                      <span>
                        <FormattedMessage id="app.name" defaultMessage="" />
                      </span>
                      <ArrowRightAltIcon />
                    </div>
                    <div onClick={() => orderFn('fileSize')}>
                      <span>
                        <FormattedMessage id="app.size" defaultMessage="" />
                      </span>
                      <ArrowRightAltIcon />
                    </div>
                    <div onClick={() => orderFn('createdAt')}>
                      <span>
                        <FormattedMessage id="app.updated" defaultMessage="" />
                      </span>
                      <ArrowRightAltIcon />
                    </div>
                    <span>
                      <FormattedMessage id="app.actions" defaultMessage="" />
                    </span>
                  </div>
                </HeaderContainer>
                <DriveContainer>
                  {folders.length > 0 && (
                    <FolderSection>
                      {orderHandler(folders).map((x) => (
                        <LicitacionesDetailMenuBFolder
                          key={x.idFolder}
                          folder={x}
                          setCurrentFolder={(x) => setCurrentFolder(x)}
                          selected={selected}
                          setSelected={(x) => setSelected(x)}
                          foldersData={foldersData}
                          filesData={filesData}
                        />
                      ))}
                    </FolderSection>
                  )}
                  {files.length > 0 && (
                    <FolderSection>
                      {orderHandler(files).map((x) => (
                        <LicitacionesDetailMenuBFile
                          key={x.idFile}
                          file={x}
                          currentFolder={currentFolder}
                          selected={selected}
                          setSelected={(x) => setSelected(x)}
                          imageFiles={files.filter((x) => x.metadata.includes('image'))}
                        />
                      ))}
                    </FolderSection>
                  )}
                </DriveContainer>
              </ContentContainer>
              {(selected.files.length > 0 || selected.folders.length > 0) && (
                <PageButtonHeader
                  name={
                    width > 500
                      ? intl.formatMessage({ id: 'app.download', defaultMessage: '' })
                      : null
                  }
                  icon={'Download'}
                  oneChild={width > 500 ? false : true}
                  onClick={() => setDownloadModal({ open: true })}
                  backgroundColor={'#192685'}
                  top={width > 500 ? '15px' : '25px'}
                  right={width > 500 ? '15px' : '15px'}
                />
              )}
            </ContentContainerRight>
          </Panel>
        </PanelGroup>
      </Container>
      {downloadModal.open && (
        <LicitacionesDetailMenuBDownloadModal
          open={downloadModal.open}
          onClose={() => setDownloadModal(false)}
          folderName={'Download'}
          selected={selected}
          foldersData={foldersData}
          filesData={filesData}
        />
      )}
    </PageContent>
  );
}

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0px 0 5px;
  overflow-y: scroll;
  height: 100%;

  @media (max-width: 500px) {
    padding: 10px 0px 0 5px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0px;

  > div:first-child {
    display: flex;
    margin-bottom: 15px;

    @media (max-width: 500px) {
      padding: 15px 15px 0 15px;
    }
  }
  > div:last-child {
    display: grid;
    grid-template-columns: 30px 3fr 0.8fr 1.2fr 0.8fr;
    align-items: center;
    height: 39px;
    border-bottom: 1px solid #e6e8eb;

    @media (max-width: 768px) {
      grid-template-columns: 30px 3.8fr 1.4fr;
    }

    > div:nth-child(1) {
      /* background-color: red; */
      .MuiSvgIcon-root {
        cursor: pointer;
      }
    }

    > span {
      font-size: 14px;
    }

    > div:nth-child(2) {
      display: flex;
      align-items: flex-end;
      cursor: pointer;

      :hover {
        background-color: whitesmoke;
        border-radius: 4px;
      }
      > span {
        padding-left: 7px;
        font-size: 14px;
      }
      > .MuiSvgIcon-root {
        display: ${(props) => (props.type === 'searchName' ? 'flex' : 'none')};
        /* color: ${(props) => props.theme.cincoColor}; */
        transform: ${(props) => (props.desc ? 'rotate(90deg)' : 'rotate(-90deg)')};
        font-size: 18px;
      }
    }

    > div:nth-child(3) {
      display: flex;
      margin-right: 25px;
      align-items: flex-end;
      justify-content: flex-end;
      cursor: pointer;

      :hover {
        background-color: whitesmoke;
        border-radius: 4px;
      }
      > span {
        padding-left: 7px;
        font-size: 14px;
      }
      > .MuiSvgIcon-root {
        display: ${(props) => (props.type === 'fileSize' ? 'flex' : 'none')};
        /* color: ${(props) => props.theme.cincoColor}; */
        transform: ${(props) => (props.desc ? 'rotate(90deg)' : 'rotate(-90deg)')};
        font-size: 18px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    > div:nth-child(4) {
      display: flex;
      margin-right: 25px;
      align-items: flex-end;
      justify-content: flex-end;
      cursor: pointer;

      :hover {
        background-color: whitesmoke;
        border-radius: 4px;
      }
      > span {
        padding-left: 7px;
        font-size: 14px;
      }
      > .MuiSvgIcon-root {
        display: ${(props) => (props.type === 'createdAt' ? 'flex' : 'none')};
        /* color: ${(props) => props.theme.cincoColor}; */
        transform: ${(props) => (props.desc ? 'rotate(90deg)' : 'rotate(-90deg)')};
        font-size: 18px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    > span:nth-child(5) {
      text-align: center;
    }
  }
`;

const DriveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 100px;

  @media (max-width: 500px) {
    min-height: 400px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: inline-block;
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const FolderSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;

  > div .Resize-Horizontal {
    background-color: black;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;

    &:hover {
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }
  }

  > div .Resize-Vertical {
    background-color: black;
    opacity: 0.2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;

    &:hover {
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
      border-top: 5px solid rgba(0, 0, 0, 0.5);
      border-bottom: 5px solid rgba(0, 0, 0, 0.5);
    }
  }
`;

const ContentContainerRight = styled.div`
  position: relative;
  height: 100%;

  > iframe {
    width: 100%;
    height: 100%;
    height: ${(props) => (props.loading ? '0px' : '100%')};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
    display: block;

    @media (max-width: 500px) {
      border-radius: 0px;
    }
  }

  > div {
    > .resizeHandle {
      width: 10px;
    }
  }
`;
