export const fileSize = (size) => {
  if (size) {
    let value;
    if (size < 1000000) {
      value = `${Math.round((size / 1000) * 10) / 10}`;
      return value.includes('.') ? value.concat(' KB') : value.concat('.0 KB');
    } else if (size < 1000000000) {
      value = `${Math.round((size / 1000000) * 10) / 10}`;
      return value.includes('.') ? value.concat(' MB') : value.concat('.0 MB');
    } else {
      value = `${Math.round((size / 1000000000) * 10) / 10}`;
      return value.includes('.') ? value.concat(' GB') : value.concat('.0 GB');
    }
  } else {
    return '--';
  }
};
