import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  licitaciones: [],
  loading: false,
  error: null,
};

const licitacionesSlice = createSlice({
  name: 'licitaciones',
  initialState,
  reducers: {
    setLicitaciones: (state, action) => {
      state.licitaciones = action.payload;
    },
    setLicitacionesLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLicitacionesError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLicitaciones, setLicitacionesLoading, setLicitacionesError } =
  licitacionesSlice.actions;

export const selectLicitaciones = (state) => state.licitaciones.licitaciones;
export const selectLicitacionesLoading = (state) => state.licitaciones.loading;
export const selectLicitacionesError = (state) => state.licitaciones.error;

export default licitacionesSlice.reducer;
