import * as icons from '@mui/icons-material';
import styled from 'styled-components';

export default function MuiIcon({
  id,
  name,
  onClick,
  fontSize,
  margin,
  rotate,
  pointer,
  color,
  hoverColor,
  position,
  top,
  right,
  left,
  bottom,
}) {
  const Icon = icons[name];
  if (Icon) {
    return (
      <Container
        id={id}
        fontSize={fontSize}
        margin={margin}
        rotate={rotate}
        pointer={pointer}
        color={color}
        position={position}
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        hoverColor={hoverColor}
      >
        <Icon onClick={onClick} />
      </Container>
    );
  } else {
    return <></>;
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  position: ${(props) => (props.position ? props.position : null)};
  top: ${(props) => (props.top ? props.top : null)};
  right: ${(props) => (props.right ? props.right : null)};
  bottom: ${(props) => (props.bottom ? props.bottom : null)};
  left: ${(props) => (props.left ? props.left : null)};

  .MuiSvgIcon-root {
    font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
    color: ${(props) => (props.color ? props.color : null)};
    margin: ${(props) => (props.margin ? props.margin : null)};
    transform: ${(props) =>
      props.rotate
        ? props.rotate === 'rotate(90deg)'
          ? 'rotate(90deg)'
          : 'rotate(180deg)'
        : 'rotate(0deg)'};
    cursor: ${(props) => (props.pointer ? 'pointer' : null)};

    :hover {
      color: ${(props) => (props.hoverColor ? props.hoverColor : null)};
    }
  }
`;
