import React from 'react';

import ModalContainer from '../generales/ModalContainer';
import DocView from './DocView';

export default function DocViewerModal({ open, onClose, url, fileName, idFile }) {
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={fileName}
      maxHeight={'90%'}
      maxWidth={'100%'}
      downloadUrl={url}
    >
      <DocView
        url={url}
        fileName={fileName}
        idFile={idFile}
        modal={true}
        height={'calc(100vh - 100px)'}
      />
    </ModalContainer>
  );
}
