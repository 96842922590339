import React from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';

import animationData from '../../images/loadingScreen/13525-empty.json';

function EmptyScreen({ text }) {
  return (
    <Container>
      <Lottie animationData={animationData} loop={true} autoplay={true} height={300} width={300} />
      <LoadingText>{text ? text : ''}</LoadingText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const LoadingText = styled.span`
  margin-top: 24px;
  text-align: center;
  font-weight: 300;
`;

export default EmptyScreen;
