import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

import PageNotFound from '../generales/PageNotFound';
import Licitaciones from '../licitaciones/Licitaciones';
import Perfil from '../perfil/Perfil';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLicitacionesLoading,
  setLicitaciones,
  setLicitacionesError,
  setLicitacionesLoading,
} from '../../features/licitaciones/licitacionesSlice';
import LicitacionesDetail from '../licitaciones/Licitaciones_detail';

export default function HomeRoutes() {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector(selectLicitacionesLoading);

  useEffect(() => {
    if (!loading && location.pathname === '/') {
      // console.log('///////////////////////////');
      // console.log('loading');
      // console.log('///////////////////////////');
      dispatch(setLicitacionesLoading(true));
      (async () => {
        try {
          const functions = getFunctions();
          const licitaciones_oncall = httpsCallable(functions, 'licitaciones_oncall');
          const result = await licitaciones_oncall();
          dispatch(setLicitaciones(result.data?.licitaciones || []));
          dispatch(setLicitacionesLoading(false));
        } catch (err) {
          // console.log('aca');
          dispatch(setLicitacionesLoading(false));
          dispatch(setLicitacionesError(err));
        }
      })();
    }
  }, [location.pathname]);

  return (
    //prettier-ignore
    <Routes>
      {/* ------------------LICITACIONES--------------------- */}
      <Route exact path="/" element={<Licitaciones />} />

      {/* ------------------PROFILE--------------------- */}
      <Route exact path="/perfil" element={<Perfil />} />

      {/* ------------------PROFILE--------------------- */}
      <Route exact path="/licitaciones/:idLicitacion/detail" element={<LicitacionesDetail />} />

      {/* ------------------404--------------------- */}
      <Route path='*' element={<PageNotFound backButton={true} />}/>
    </Routes>
  );
}
