import React, { useEffect, useState, useRef } from 'react';
import OpenSeadragon from 'openseadragon';

export default function ZoomabeImageViewer({ url }) {
  //open sea dragon
  // const viewerRef = useRef(null);
  // useEffect(() => {
  //   const viewer = OpenSeadragon({
  //     id: 'viewer',
  //     prefixUrl: 'https://cdnjs.cloudflare.com/ajax/libs/openseadragon/2.4.2/images/',
  //     tileSources: 'http://localhost:5000/static/image.dzi',
  //   });
  //   viewerRef.current = viewer;
  //   return () => {
  //     viewerRef.current.destroy();
  //   };
  // }, []);
  // return <div id="viewer" style={{ width: '100%', height: '100%', borderRadius: '8px' }} />;

  //zoomhub
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <iframe
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none', borderRadius: '8px', backgroundColor: 'red' }}
        title="ZoomableImageViewer"
      />
    </div>
  );
}
