import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helpers from './forge-viewer-helper';
import styled from 'styled-components';

export default function Viewer({ urn, role, viewerId, viewer }) {
  const dispatch = useDispatch();

  useEffect(() => {
    Helpers.launchViewer(urn, role, dispatch, viewerId, viewer);
  }, []);

  return (
    <Container>
      <div id={viewerId}></div>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  > div {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
  }
`;
