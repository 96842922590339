import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';

import ModalContainer from '../generales/ModalContainer';
import { deleteObject, ref } from 'firebase/storage';
import { db, storage } from '../../firebase/firebase';
import { deleteDoc, doc } from 'firebase/firestore';

export default function LicitacionesDetailMenuDFileDeleteModal({ open, onClose, file }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    try {
      setLoading(true);
      const storageRef = ref(storage, file.path);
      await deleteObject(storageRef);
      await deleteDoc(doc(db, `licitaciones_files/${file.idFile}`));
      onClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(intl.formatMessage({ id: 'app.alerterror', defaultMessage: '' }));
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${intl.formatMessage({ id: 'app.delete', defaultMessage: '' })} ${intl.formatMessage({
        id: 'app.document',
        defaultMessage: '',
      })}`}
      maxWidth={'400px'}
      buttonsArray={[
        {
          name: intl.formatMessage({ id: 'app.delete', defaultMessage: '' }),
          isValid: true,
          onClick: () => deleteHandler(),
          loading,
        },
      ]}
    >
      <ContentContainer>
        <span>
          <FormattedMessage id="app.minutas.modaltextG" defaultMessage="" />
        </span>
      </ContentContainer>
    </ModalContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px;

  > span {
    font-size: 14px;
    font-weight: 300;
  }
`;
