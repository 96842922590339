import { getFunctions, httpsCallable } from 'firebase/functions';
import { encode } from 'base-64';

export const getAccessToken = async (role) => {
  const functions = getFunctions();
  const forgeAuthInstance = httpsCallable(functions, 'forge_auth');
  try {
    const forgeResponse = await forgeAuthInstance();
    // console.log(forgeResponse.data);
    return forgeResponse.data;
  } catch (err) {
    console.log(err);
  }

  // Response Data
  // {
  //   "access_token":"YOUR_ACCESS_TOKEN",
  //   "token_type":"Bearer",
  //   "expires_in":3599
  // }
};

export const createBucket = async (token, bucketKey) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      bucketKey,
      access: 'full',
      policyKey: 'persistent',
    }),
  };

  try {
    const response = await fetch('https://developer.api.autodesk.com/oss/v2/buckets', options);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "bucketKey": "YOUR_BUCKET_KEY",
  //   "bucketOwner": "YOUR_FORGE_APP_CLIENT_ID",
  //   "createdDate": 1571296694595,
  //   "permissions": [
  //       {
  //           "authId": "T05H372IE11Kmkksdh73ndj0qie2f6nib",
  //           "access": "full"
  //       }
  //   ],
  //   "policyKey": "transient"
  // }
};

export const uploadForgeFile = async (file, fileName, token, bucketKey) => {
  const options = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Encoding': 'gzip, deflate',
    },
    body: file,
  };

  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects/${file.name}`,
      options
    );
    const data = await response.json();
    //encode urn to base64
    const objectId_base64 = encode(data.objectId);
    // console.log(data);
    return { ...data, objectId_base64 };
  } catch (err) {
    console.log(err);
  }

  // Response Data
  // {
  //   "bucketKey": "YOUR_BUCKET_KEY",
  //   "objectId": "urn:adsk.objects:os.object:YOUR_BUCKET_KEY/OBJECT_KEY_4_INPUT_FILE",
  //   "objectKey": "OBJECT_KEY_4_SOURCE_FILE",
  //   "sha1": "d85e70ee395a580d68a3011cb3fe626fe0d89cbf",
  //   "size": 134656,
  //   "contentType": "application/octet-stream",
  //   "location": "https://developer.api.autodesk.com/oss/v2/buckets/YOUR_BUCKET_KEY/objects/OBJECT_KEY_4_INPUT_FILE"
  // }
};

export const startTranslationJob_To_Obj = async (objectId_base64, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        urn: objectId_base64,
      },
      output: {
        destination: {
          region: 'us',
        },
        formats: [{ type: 'obj' }],
      },
    }),
  };

  try {
    const response = await fetch(
      'https://developer.api.autodesk.com/modelderivative/v2/designdata/job',
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "result": "success",
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "registerKeys": [
  //       "c7348a17-9e49-4752-8713-75ba2576b87c"
  //   ],
  //   "acceptedJobs": {
  //       "output": {
  //           "destination": {
  //               "region": "us"
  //           },
  //           "formats": [
  //               {
  //                   "type": "obj"
  //               }
  //           ]
  //       }
  //   }
  // }
};

export const startTranslationJob_To_SVF = async (objectId_base64, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        urn: objectId_base64,
        // rootFilename: 'Suspension.iam',
        // compressedUrn: true,
      },
      output: {
        destination: {
          region: 'us',
        },
        formats: [
          {
            type: 'svf',
            views: ['2d', '3d'],
            // solo si queremos generar room y space information
            // advanced: {
            //   "generateMasterViews": true
            // }
          },
        ],
      },
    }),
  };

  try {
    const response = await fetch(
      'https://developer.api.autodesk.com/modelderivative/v2/designdata/job',
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "result": "success",
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "acceptedJobs": {
  //       "output": {
  //           "destination": {
  //               "region": "us"
  //           },
  //           "formats": [
  //               {
  //                   "type": "svf",
  //                   "views": [
  //                       "2d",
  //                       "3d"
  //                   ]
  //               }
  //           ]
  //       }
  //   }
  // }
};

export const startTranslationJob_To_SVF2 = async (objectId_base64, token) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        urn: objectId_base64,
        // rootFilename: 'Suspension.iam',
        // compressedUrn: true,
      },
      output: {
        destination: {
          region: 'us',
        },
        formats: [
          {
            type: 'svf2',
            views: ['2d', '3d'],
            // solo si queremos generar room y space information
            // advanced: {
            //   "generateMasterViews": true
            // }
          },
        ],
      },
    }),
  };

  try {
    const response = await fetch(
      'https://developer.api.autodesk.com/modelderivative/v2/designdata/job',
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "result": "success",
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "acceptedJobs": {
  //       "output": {
  //           "destination": {
  //               "region": "us"
  //           },
  //           "formats": [
  //               {
  //                   "type": "svf",
  //                   "views": [
  //                       "2d",
  //                       "3d"
  //                   ]
  //               }
  //           ]
  //       }
  //   }
  // }
};

export const obtainMetadata = async (token, url_safe_of_source) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/modelderivative/v2/designdata/${url_safe_of_source}/metadata`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const checkTranslationStatus = async (token, url_safe_of_source) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/modelderivative/v2/designdata/${url_safe_of_source}/manifest`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data (starting)
  // {
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "derivatives": [],
  //   "hasThumbnail": "false",
  //   "progress": "0% complete",
  //   "type": "manifest",
  //   "region": "US",
  //   "version": "1.0",
  //   "status": "inprogress"
  // }

  // Response Data (in progress)
  // {
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "derivatives": [
  //       {
  //           "hasThumbnail": "false",
  //           "children": [
  //               {
  //                   "urn": "urn:adsk.viewing:fs.file:URL_SAFE_URN_OF_SOURCE_FILE/output/54c07512-6b0c-41fd-8a13-012e0fff3ca9/box.obj",
  //                   "role": "OBJ",
  //                   "mime": "application/octet-stream",
  //                   "guid": "8eac4812-11bd-446a-b8d0-f1d09ebf8d03",
  //                   "type": "resource",
  //                   "status": "success"
  //               }
  //           ],
  //           "name": "box.ipt",
  //           "progress": "50% complete",
  //           "outputType": "obj",
  //           "status": "success"
  //       }
  //   ],
  //   "hasThumbnail": "false",
  //   "progress": "50% complete",
  //   "type": "manifest",
  //   "region": "US",
  //   "version": "1.0",
  //   "status": "inprogress"
  // }

  // Response Data (complete)
  // {
  //   "urn": "URL_SAFE_URN_OF_SOURCE_FILE",
  //   "derivatives": [
  //       {
  //           "hasThumbnail": "false",
  //           "children": [
  //               {
  //                   "urn": "urn:adsk.viewing:fs.file:URL_SAFE_URN_OF_SOURCE_FILE/output/54c07512-6b0c-41fd-8a13-012e0fff3ca9/box.obj",
  //                   "role": "OBJ",
  //                   "mime": "application/octet-stream",
  //                   "guid": "8eac4812-11bd-446a-b8d0-f1d09ebf8d03",
  //                   "type": "resource",
  //                   "status": "success"
  //               },
  //               {
  //                   "urn": "urn:adsk.viewing:fs.file:URL_SAFE_URN_OF_SOURCE_FILE/output/54c07512-6b0c-41fd-8a13-012e0fff3ca9/box.mtl",
  //                   "role": "OBJ",
  //                   "mime": "application/octet-stream",
  //                   "guid": "4e00b88e-cbcb-4644-b6ae-5ef3d206d27d",
  //                   "type": "resource",
  //                   "status": "success"
  //               }
  //           ],
  //           "name": "box.ipt",
  //           "progress": "complete",
  //           "outputType": "obj",
  //           "status": "success"
  //       }
  //   ],
  //   "hasThumbnail": "false",
  //   "progress": "complete",
  //   "type": "manifest",
  //   "region": "US",
  //   "version": "1.0",
  //   "status": "success"
  // }
};

export const downloadObjFile = async (token, url_safe_of_source, urn_of_obj_file) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/modelderivative/v2/designdata/${url_safe_of_source}/manifest/${urn_of_obj_file}`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // HTTP/1.1 200 OK
  // Accept-Ranges: bytes
  // Access-Control-Allow-Origin: *
  // Cache-Control: public, max-age=86400
  // Content-Type: application/octet-stream
  // Date: Mon, 09 Mar 2020 12:06:24 GMT
  // ETag: "91644bb9a87128ecd9d9e3bf8768f923"
  // Strict-Transport-Security: max-age=31536000; includeSubDomains
  // x-ads-app-identifier: platform-viewing-2020.01.01.97.5c80940-production
  // x-ads-duration: 171 ms
  // x-ads-region: US
  // x-ads-startup-time: Sat Mar 07 08:07:34 UTC 2020
  // transfer-encoding: chunked
  // Connection: keep-alive

  // with chunked content body
};

export const listHubs = async (token) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(`https://developer.api.autodesk.com/project/v1/hubs`, options);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const listBuckets = async (token) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(`https://developer.api.autodesk.com/oss/v2/buckets`, options);
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "items" : [ {
  //     "bucketKey" : "00001fbf-8505-49ab-8a42-44c6a96adbd0",
  //     "createdDate" : 1441329298362,
  //     "policyKey" : "transient"
  //   }, {
  //     "bucketKey" : "0003114d",
  //     "createdDate" : 1440119769765,
  //     "policyKey" : "transient"
  //   }, {
  //     "bucketKey" : "000e37cc",
  //     "createdDate" : 1437441338604,
  //     "policyKey" : "transient"
  //   } ],
  //   "next" : "https://developer.api.autodesk.com/oss/v2/buckets?region=US&startAt=000e37cc"
  // }
};

export const getBucketDetails = async (token, bucketKey) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/details`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "bucketKey":"apptestbucket",
  //   "bucketOwner":"RlKffonB1Ex52GlAbb7N8VJwLllOvpqG",
  //   "createdDate":1463785698600,
  //   "permissions":[
  //     {
  //       "authId":"RlKffonB1Ex52GlAbb7N8VJwLllOvpqG",
  //       "access":"full"
  //     }
  //   ],
  //   "policyKey":"transient"
  // }
};

export const deleteBucket = async (token, bucketKey) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const listBucketObjects = async (token, bucketKey) => {
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await fetch(
      `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects`,
      options
    );
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }

  // Response Data
  // {
  //   "items" : [
  //     {
  //       "bucketKey" : "apptestbucket",
  //       "objectKey" : "objectKeyFoo",
  //       "objectId" : "urn:adsk.objects:os.object:apptestbucket/objectKeyFoo",
  //       "sha1" : "cdbf71bfc07cbc18372a5dd4b6e161463cb7fd35",
  //       "size" : 7,
  //       "location" : "https://developer.api.autodesk.com/oss/v2/buckets/apptestbucket/objects/objectKeyFoo"
  //     }
  //   ],
  //   "next" : "https://developer.api.autodesk.com/oss/v2/buckets/apptestbucket/objects?startAt=objectKeyFoo&limit=1"
  // }
};
