import React from 'react';
import styled from 'styled-components';

import NoChartDataScreen from './NoChartDataScreen';

export default function NoViews({ text }) {
  return (
    <Container>
      <div>
        <NoChartDataScreen text={text ? text : 'No se han generado vistas del archivo'} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: scroll;

  @media (max-width: 500px) {
    width: 100%;
    border-radius: 0px;
    margin: 0px;
    /* padding: 15px; */
  }

  > div:last-child {
    display: flex;
    height: 100%;
    max-width: 400px;
  }
`;
