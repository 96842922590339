import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import styled from 'styled-components';
import HomeIcon from '@mui/icons-material/Home';

export default function LicitacionesDetailMenuBBreadCrumbs({
  currentFolder,
  setCurrentFolder,
  setSelected,
  folders,
}) {
  const path = [...(currentFolder?.path || [])];

  return (
    <Container role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <FirstItem
          onClick={() => {
            setCurrentFolder(null);
            setSelected();
          }}
        >
          <HomeIcon />
        </FirstItem>
        {path.map((x) => (
          <MiddleItem
            key={x.idFolder}
            onClick={() => {
              setCurrentFolder(folders.find((y) => y.idFolder === x.idFolder));
            }}
          >
            <span>{x?.name}</span>
          </MiddleItem>
        ))}
        {currentFolder && (
          <LastItem>
            <span>{currentFolder.name}</span>
          </LastItem>
        )}
      </Breadcrumbs>
    </Container>
  );
}

const Container = styled.div`
  margin-left: 15px;
`;

const FirstItem = styled.div`
  display: flex;
  align-self: center;
  > .MuiSvgIcon-root {
    font-size: 20px;
    color: rgba(82, 74, 62, 0.82);
    cursor: pointer;
  }
`;

const MiddleItem = styled.div`
  display: flex;
  align-self: center;
  > span {
    color: rgba(82, 74, 62, 0.82);
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LastItem = styled.div`
  display: flex;
  align-self: center;
  > span {
    color: rgba(82, 74, 62, 0.82);
    font-size: 14px;
    font-weight: 400;
  }
`;
