import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Spinner from 'react-spinkit';

import LoadingScreen from '../generales/LoadingScreen';
import { orderBy } from '../../helpers/orderBy';
import { firestoreDate } from '../../helpers/firestoreDate';
import EmptyScreen from '../generales/EmptyScreen';
import PageLoadingError from '../generales/PageLoadingError';
import ZoomabeImageViewer from '../zoomableImageViewer/ZoomableImageViewer';

export default function LicitacionesDetailMenuI({ licitacion, loading, error }) {
  const intl = useIntl();

  const [loadingUrl, setLoadingUrl] = useState(true);
  const [contour, setContour] = useState(
    orderBy([...(licitacion?.ortho || [])], 'date').reverse()[0]
  );

  return loading ? (
    <ContentContainer>
      <LoadingScreen text={intl.formatMessage({ id: 'app.loadingdata' })} />
    </ContentContainer>
  ) : error ? (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  ) : (
    <ContentContainer>
      <InputContainer>
        <ContourContainer loading={loadingUrl}>
          <>
            <FileNameContainer>
              <div>
                {contour?.label}: {firestoreDate(contour?.date, false, intl.locale)}
              </div>
            </FileNameContainer>
            <ZoomabeImageViewer url={'https://zoomhub.net/q0Xk6'} />
          </>
        </ContourContainer>
      </InputContainer>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: white;
  border-radius: 8px;
`;

const ContourContainer = styled.div`
  position: relative;
  height: 100%;

  > iframe {
    width: 100%;
    height: 100%;
    height: ${(props) => (props.loading ? '0px' : '100%')};
    border: none;
    display: block;
    border-radius: 8px;

    @media (max-width: 500px) {
      border-radius: 0px;
    }
  }

  > div {
    > .resizeHandle {
      width: 10px;
    }
  }
`;

const FileNameContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 998;

  > div {
    padding: 3px 10px;
    border-radius: 4px;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-size: 12px;
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
