import React from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CircularProgress } from '@mui/material';

function ModalAlert({ open, onClose, text }) {
  const closeHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
  };
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      closeAfterTransition
      disableEscapeKeyDown={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Fade in={open} style={{ outline: 0 }}>
        <Container>
          <Title>{text}</Title>
          <CircularProgress size={20} color="inherit" style={{ marginRight: 10 }} />
        </Container>
      </Fade>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.theme.name === 'light' ? 'white' : 'black')};
  width: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  overflow-y: scroll;
  max-height: 70%;
`;

const Title = styled.span`
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 30px;
`;

export default ModalAlert;
